import React from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useScreenSize } from '../../hooks/useScreenSize';

export default function GridEditFormDialog(props) {
	const { table, row, objectType, title, children, requiredFieldMap, validateFields, onValidate } = props,
		{
			getState,
			options: { onCreatingRowCancel, onCreatingRowSave, onEditingRowCancel, onEditingRowSave },
			setCreatingRow,
			setEditingRow,
		} = table,
		{ creatingRow, editingRow } = getState(),
		isCreating = creatingRow?.id === row.id,
		isEditing = editingRow?.id === row.id,
		displayTitle = title || `${isCreating ? 'Create ' : 'Edit '} ${objectType}`,
		{ buttonSize, buttonVariant } = useScreenSize(),
		handleCancel = () => {
			if (isCreating) {
				onCreatingRowCancel?.({ row, table });
				setCreatingRow(null);
			} else if (isEditing) {
				onEditingRowCancel?.({ row, table });
				setEditingRow(null);
			}
			row._valuesCache = {};
		},
		handleSave = () => {
			const isValid = validate();
			if (!isValid) {
				return;
			}
			if (isCreating)
				onCreatingRowSave?.({
					exitCreatingMode: () => setCreatingRow(null),
					row,
					table,
					values: row._valuesCache,
				});
			else if (isEditing) {
				onEditingRowSave?.({
					exitEditingMode: () => setEditingRow(null),
					row,
					table,
					values: row._valuesCache,
				});
			}
		},
		validate = () => {
			let errors = {};
			if (validateFields) {
				errors = validateFields();
			} else if (requiredFieldMap) {
				Object.keys(requiredFieldMap).forEach((f) => {
					const value = row._valuesCache[f];
					if (!value) errors[f] = `${requiredFieldMap[f]} is required.`;
				});
			}
			row._validationErrors = errors;
			onValidate(errors);
			return !errors || Object.keys(errors).length === 0;
		};
	return (
		<>
			<DialogTitle>{displayTitle}</DialogTitle>
			<DialogContent dividers>{children}</DialogContent>
			<DialogActions>
				<Button size={buttonSize} variant={'outlined'} onClick={handleCancel}>
					Cancel
				</Button>
				<Button size={buttonSize} variant={buttonVariant} onClick={handleSave}>
					Save
				</Button>
			</DialogActions>
		</>
	);
}
