import React from 'react';

import LogsTable from '../logsTable';

const Timecards = ({ timecardData, timecardDataRefreshed, initialPage, initialPageSize }) => {
	return (
		<LogsTable
			data={timecardData}
			logDataRefreshed={timecardDataRefreshed}
			initialPage={initialPage}
			initialPageSize={initialPageSize}
			type="timecard"
		/>
	);
};

export default Timecards;
