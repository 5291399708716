import React, { useState, useEffect } from 'react';

import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import ChildLocationsManager from './ChildLocationsManager';
import WorkItemManager from './WorkItemManager';

export default function LocationWorkItemManager({ project, selectedLocationId, setSelectedLocationId }) {
	const [breadcrumbs, setBreadcrumbs] = useState([]),
		{ projectId } = useParams(),
		navigate = useNavigate();

	useEffect(() => {
		calculateBreadcrumbs(selectedLocationId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId, selectedLocationId, project.locations]);

	const calculateBreadcrumbs = (locationId) => {
			const breadcrumbsArray = [{ id: project.id, name: project.name, isProject: true }];

			if (project.locations && project.locations.length > 0 && locationId) {
				const findLocationPath = (locations, targetId, path = []) => {
						for (const location of locations) {
							if (location.id === targetId) {
								return [...path, location];
							}
							if (location.children) {
								const result = findLocationPath(location.children, targetId, [...path, location]);
								if (result) return result;
							}
						}
						return null;
					},
					locationPath = findLocationPath(project.locations, locationId);
				if (locationPath) {
					breadcrumbsArray.push(
						...locationPath.map((loc) => ({ id: loc.id, name: loc.name, isProject: false }))
					);
				}
			}

			setBreadcrumbs(breadcrumbsArray);
		},
		handleLocationClick = (crumb) => {
			if (crumb.isProject) {
				setSelectedLocationId(null);
				navigate(`/projects/${projectId}`);
			} else {
				setSelectedLocationId(crumb.id);
				navigate(`/projects/${projectId}/locations/${crumb.id}`);
			}
		};

	return (
		<Box sx={{ width: '100%' }}>
			<Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
				{breadcrumbs.map((crumb) => (
					<Link
						key={crumb.id}
						color="inherit"
						href="#"
						onClick={() => handleLocationClick(crumb)}
						sx={{
							fontSize: '0.875rem',
							'&:hover': { textDecoration: 'underline' },
						}}
					>
						<Typography variant="body2" component="span">
							{crumb.name}
						</Typography>
					</Link>
				))}
			</Breadcrumbs>

			{selectedLocationId && <WorkItemManager projectId={projectId} locationId={selectedLocationId} />}

			<ChildLocationsManager project={project} locationId={selectedLocationId} />
		</Box>
	);
}
