import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

const BlankLayoutWrapper = styled(Box)(({ theme }) => ({
	height: '100vh',

	// For V1 Blank layout pages
	'& .content-center': {
		display: 'flex',
		minHeight: '100vh',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(5),
	},

	// For V2 Blank layout pages
	'& .content-right': {
		display: 'flex',
		minHeight: '100vh',
		overflowX: 'hidden',
		position: 'relative',
	},
}));

export const BlankLayout = () => {
	return (
		<BlankLayoutWrapper className="layout-wrapper">
			<Box className="app-content" sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
				<Outlet />
			</Box>
		</BlankLayoutWrapper>
	);
};
