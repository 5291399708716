import React, { useState, useEffect, useCallback } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

//import AssignProjectTabPanel from './assignProjectTabPanel';
import SetupProjectTabPanel from './setupProjectTabPanel';
import AppContainer from '../../components/appContainer';
import { LoadingContext } from '../../context';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`project-tabpanel-${index}`}
			aria-labelledby={`project-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export default function ProjectManagementPage() {
	const [tabIndex, setTabIndex] = useState(0),
		navigate = useNavigate(),
		[projects, setProjects] = useState([]),
		[isProjectsLoaded, setIsProjectsLoaded] = useState(false),
		[isFetchingProjects, setIsFetchingProjects] = useState(false),
		{ httpGet } = useFetchWithMsal(),
		{ startLoading, stopLoading } = React.useContext(LoadingContext),
		{ enqueueSnackbar } = useSnackbar(),
		handleTabChange = (event, newValue) => {
			setTabIndex(newValue);
		},
		handleProjectClick = (projectId) => {
			navigate(`/projects/${projectId}`);
		},
		a11yProps = (index) => {
			return {
				id: `project-tab-${index}`,
				'aria-controls': `project-tabpanel-${index}`,
			};
		},
		loadProjects = useCallback(() => {
			if (isFetchingProjects) return;
			setIsFetchingProjects(true);
			startLoading();
			httpGet({
				urlSubPath: 'job',
			})
				.then((response) => {
					setProjects(response);
					setIsProjectsLoaded(true);
				})
				.catch((error) => {
					console.error('Error loading projects:', error);
					enqueueSnackbar('Unable to load projects', { variant: 'error', preventDuplicate: true });
				})
				.finally(() => {
					setIsFetchingProjects(false);
					stopLoading();
				});
		}, [enqueueSnackbar, httpGet, startLoading, stopLoading, isFetchingProjects]);

	useEffect(() => {
		if (!isProjectsLoaded && !isFetchingProjects) {
			loadProjects();
		}
	}, [isProjectsLoaded, isFetchingProjects, loadProjects]);

	return (
		<AppContainer>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={tabIndex} onChange={handleTabChange} centered>
					<Tab label="Setup Project" {...a11yProps(0)} />
					{/* <Tab label="Assign Project" {...a11yProps(1)} /> */}
				</Tabs>
			</Box>
			<CustomTabPanel value={tabIndex} index={0}>
				<SetupProjectTabPanel
					projects={projects}
					refreshProjects={() => setIsProjectsLoaded(false)}
					onProjectClick={handleProjectClick}
				/>
			</CustomTabPanel>
			{/* <CustomTabPanel value={tabIndex} index={1}>
				<AssignProjectTabPanel />
			</CustomTabPanel> */}
		</AppContainer>
	);
}
