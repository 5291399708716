import { get, post, put } from '../utils/httpRequestUtils';

const getUserInfo = async (accessToken, account) => {
		const { username, localAccountId } = account,
			externalId = localAccountId;

		let user = await findUser(accessToken, username, externalId);
		user = await syncUserInfo(accessToken, account, user);
		return user;
	},
	findUser = async (accessToken, username, externalId) => {
		const urlSubPath = 'user',
			params = {
				urlSubPath,
				accessToken,
				body: {
					username: username && username.toLowerCase(),
					externalId,
				},
			};
		return await post(params);
	},
	syncUserInfo = async (accessToken, account, user) => {
		const { username, localAccountId, name, idTokenClaims } = account,
			externalId = localAccountId;

		let firstName = account.given_name,
			lastName = account.family_name;

		if (idTokenClaims) {
			firstName = idTokenClaims.given_name || firstName;
			lastName = idTokenClaims.family_name || lastName;
		}

		if (!user.id) {
			user = await createUser(accessToken, {
				username: username || externalId,
				externalId,
				name,
				firstName,
				lastName,
				email: username,
				isActive: true,
			});
		} else if (user && (!user.externalId || !user.username)) {
			user = await createOrUpdateUser(accessToken, {
				id: user.id,
				username: user.email || username || externalId,
				name,
				firstName: user.firstName,
				lastName: user.lastName,
				externalId: user.externalId || externalId,
				email: user.email,
				isActive: user.isActive,
				roles: user.roles.map((r) => r.roleName),
			});
		}
		return user;
	},
	createUser = async (accessToken, userInfo) => {
		return createOrUpdateUser(accessToken, userInfo);
	},
	getUsers = async (accessToken) => {
		const urlSubPath = 'users',
			params = {
				urlSubPath,
				accessToken,
			},
			results = await get(params),
			users = results.map((u) => {
				return { ...u, name: `${u.firstName} ${u.lastName}` };
			});
		return users;
	},
	createOrUpdateUser = async (accessToken, userInfo) => {
		const urlSubPath = 'user',
			params = {
				urlSubPath,
				accessToken,
				body: userInfo,
			};

		return await put(params);
	};

export { getUserInfo, createUser, getUsers, createOrUpdateUser };
