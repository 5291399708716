import React, { useState, useEffect, useCallback, useContext } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FolderIcon from '@mui/icons-material/Folder';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Button,
	Menu,
	MenuItem,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import AppContainer from '../../components/appContainer';
import { LoadingContext } from '../../context';
import { AuthContext } from '../../context/auth/AuthContext';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';

export default function DocumentsPage() {
	const [projects, setProjects] = useState([]),
		[currentProject, setCurrentProject] = useState(null),
		[anchorEl, setAnchorEl] = useState(null),
		[newFolderName, setNewFolderName] = useState(''),
		[isNewFolderDialogOpen, setIsNewFolderDialogOpen] = useState(false),
		{ httpGet, httpPost } = useFetchWithMsal(),
		{ getUserId } = useContext(AuthContext),
		{ startLoading, stopLoading } = useContext(LoadingContext),
		{ enqueueSnackbar } = useSnackbar(),
		loadProjects = useCallback(() => {
			startLoading();
			console.log('Loading project doc references');
			httpGet({
				urlSubPath: 'projects/doc-references',
			})
				.then((response) => {
					console.log('Received project data:', response);
					setProjects(response);
				})
				.catch((error) => {
					console.error('Error loading project doc references:', error);
					enqueueSnackbar('Unable to load project doc references', {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					stopLoading();
				});
		}, [httpGet, enqueueSnackbar, startLoading, stopLoading]);

	useEffect(() => {
		loadProjects();
	}, []);

	const handleProjectClick = (project) => {
			setCurrentProject(project);
		},
		handleBackClick = () => {
			setCurrentProject(null);
		},
		handleNewClick = (event) => {
			setAnchorEl(event.currentTarget);
		},
		handleNewClose = (action) => {
			setAnchorEl(null);
			if (action === 'folder') {
				setIsNewFolderDialogOpen(true);
			}
		},
		handleCreateFolder = async () => {
			if (!newFolderName.trim()) {
				enqueueSnackbar('Folder name cannot be empty', { variant: 'error' });
				return;
			}

			try {
				startLoading();
				const response = await httpPost({
					urlSubPath: `projects/${currentProject.id}/doc-references`,
					body: {
						userId: getUserId(),
						name: newFolderName,
						type: 'FOLDER',
					},
				});

				console.log('New folder created:', response);
				enqueueSnackbar('Folder created successfully', { variant: 'success' });
				setIsNewFolderDialogOpen(false);
				setNewFolderName('');
				loadProjects(); // Refresh the document list
			} catch (error) {
				console.error('Error creating folder:', error);
				enqueueSnackbar('Failed to create folder', { variant: 'error' });
			} finally {
				stopLoading();
			}
		};

	return (
		<AppContainer>
			<Box sx={{ width: '100%', typography: 'body1', p: 3 }}>
				<Typography variant="h4" sx={{ my: 2 }}>
					{currentProject ? currentProject.name : 'Projects'}
				</Typography>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
					{currentProject && (
						<IconButton onClick={handleBackClick} sx={{ mr: 1 }}>
							<ArrowBackIcon />
						</IconButton>
					)}
					<IconButton onClick={loadProjects} sx={{ mr: 1 }}>
						<RefreshIcon />
					</IconButton>
					<Button variant="contained" onClick={handleNewClick} disabled={!currentProject}>
						New
					</Button>
					<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleNewClose()}>
						<MenuItem onClick={() => handleNewClose('folder')}>New folder</MenuItem>
						<MenuItem onClick={() => handleNewClose('upload')}>New upload</MenuItem>
					</Menu>
				</Box>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="documents table">
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell align="right">Last Modified</TableCell>
								<TableCell align="right">File Size</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(currentProject ? currentProject.documentReferences : projects).map((item) => (
								<TableRow
									key={item.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
									onClick={() => handleProjectClick(item)}
								>
									<TableCell component="th" scope="row">
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<FolderIcon sx={{ mr: 1 }} />
											{item.name}
										</Box>
									</TableCell>
									<TableCell align="right">{item.lastModified || '-'}</TableCell>
									<TableCell align="right">{item.size || '-'}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				<Dialog open={isNewFolderDialogOpen} onClose={() => setIsNewFolderDialogOpen(false)}>
					<DialogTitle>Create New Folder</DialogTitle>
					<DialogContent>
						<TextField
							margin="dense"
							id="name"
							label="Folder Name"
							type="text"
							fullWidth
							variant="outlined"
							value={newFolderName}
							onChange={(e) => setNewFolderName(e.target.value)}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setIsNewFolderDialogOpen(false)}>Cancel</Button>
						<Button onClick={handleCreateFolder}>Create</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</AppContainer>
	);
}
