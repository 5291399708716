import React, { useState } from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as FaIcons from 'react-icons/fa';

import { loginRequest } from '../../authConfig';
import mainLogo from '../../tkilogo.png';
import { logger } from '../../utils/loggingUtils';

function stringAvatar(name) {
	if (name) {
		const nameAsArray = name.split(' '),
			firstInitial = nameAsArray[0][0],
			lastInitial = nameAsArray.length > 1 ? nameAsArray[1][0] : '';
		return {
			children: `${firstInitial}${lastInitial}`,
		};
	}
	return '';
}

export function ResponsiveAppBar(props) {
	const [anchorElUser, setAnchorElUser] = useState(null),
		isAuthenticated = useIsAuthenticated(),
		{ instance } = useMsal(),
		account = instance.getActiveAccount(),
		userName = (account && account.name) || '',
		handleOpenUserMenu = (event) => {
			setAnchorElUser(event.currentTarget);
		},
		handleCloseUserMenu = () => {
			setAnchorElUser(null);
		},
		handleLogout = () => {
			instance.logoutRedirect({
				postLogoutRedirectUri: '/',
			});
		},
		handleLogin = () => {
			instance.loginRedirect(loginRequest).catch((e) => {
				logger.error(e);
			});
		};

	return (
		<AppBar position="fixed" sx={{ bgcolor: 'rgb(22, 22, 22)' }}>
			<Container maxWidth="false">
				<Toolbar disableGutters sx={{ minHeight: '64px' }}>
					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width="100%">
						<Stack direction={'row'} alignItems={'center'}>
							<Box>
								<div className="menu-bars">
									<FaIcons.FaBars onClick={() => props.navToApp(props.appToNav)} />
								</div>
							</Box>
							<Box pl={2} sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
								<img src={mainLogo} style={{ width: '200px' }} alt="Turnkey Interiors Logo" />
							</Box>
						</Stack>
						<Stack direction={'row'} alignItems={'center'}>
							<Box pr={1} sx={{ display: { sm: 'block' } }}>
								<Typography variant="title" color="inherit">
									{userName}
								</Typography>
							</Box>
							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title="Open settings">
									<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
										<Avatar {...stringAvatar(userName)} />
									</IconButton>
								</Tooltip>
								{isAuthenticated ? (
									<Menu
										sx={{ mt: '45px' }}
										id="menu-appbar"
										anchorEl={anchorElUser}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}
									>
										<MenuItem onClick={() => handleLogout()}>
											<Typography textAlign="center">Log Out</Typography>
										</MenuItem>
									</Menu>
								) : (
									<Menu
										sx={{ mt: '45px' }}
										id="menu-appbar"
										anchorEl={anchorElUser}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}
									>
										<MenuItem onClick={() => handleLogin()}>
											<Typography textAlign="center">Log In</Typography>
										</MenuItem>
									</Menu>
								)}
							</Box>
						</Stack>
					</Stack>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default ResponsiveAppBar;
