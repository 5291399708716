import httpResponseWrapper from './httpResponseWrapper';
const apiBasePath = process.env.REACT_APP_API_BASE_PATH,
	get = async (params) => {
		const { accessToken, urlSubPath } = params,
			headers = getHeaders({ accessToken }),
			results = await fetch(`${apiBasePath}/${urlSubPath}`, {
				method: 'GET',
				headers,
			})
				.then(httpResponseWrapper)
				.then((res) => res.json());
		return results;
	},
	put = async (params) => {
		const { accessToken, urlSubPath, body, requestId = null } = params,
			headers = getHeaders({ accessToken, requestId }),
			results = await fetch(`${apiBasePath}/${urlSubPath}`, {
				method: 'PUT',
				headers,
				body: JSON.stringify(body),
			})
				.then(httpResponseWrapper)
				.then((res) => res.json());
		return results;
	},
	post = async (params) => {
		const { accessToken, urlSubPath, requestId = null } = params,
			body = params.body,
			headers = getHeaders({ accessToken, acceptHeader: params.acceptHeader, requestId }),
			options = {
				method: 'POST',
				headers,
			};

		if (body) {
			options.body = JSON.stringify(body);
		}
		const results = await fetch(`${apiBasePath}/${urlSubPath}`, options)
			.then(httpResponseWrapper)
			.then((res) => res.json());
		return results;
	},
	getHeaders = (requestHeaders) => {
		const { accessToken, acceptHeader, noContentType, requestId } = requestHeaders,
			accept = acceptHeader || 'application/json',
			headers = {
				Accept: accept,
				Authorization: `Bearer ${accessToken}`,
			};
		if (!noContentType) {
			headers['Content-Type'] = 'application/json';
		}
		if (requestId) {
			headers['x-request-id'] = requestId;
		}
		return headers;
	};
export { get, put, post, getHeaders };
