import useMediaQuery from '@mui/material/useMediaQuery';
const SIZE = {
		extraSmall: 'extraSmall',
		small: 'small',
		medium: 'medium',
		large: 'large',
		extraLarge: 'extraLarge',
	},
	SHORT_SIZE = {
		extraSmall: 'xs',
		small: 'sm',
		medium: 'md',
		large: 'lg',
		extraLarge: 'xl',
	},
	useScreenSize = () => {
		const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.up('xs')),
			isSmallScreen = useMediaQuery((theme) => theme.breakpoints.up('sm')),
			isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md')),
			isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg')),
			isExtraLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('xl'));
		let buttonVariant = 'contained',
			size = SIZE.medium,
			buttonSize = SIZE.medium;
		if (isLargeScreen || isExtraLargeScreen) {
			size = SIZE.large;
			buttonSize = SIZE.large;
		} else if ((isSmallScreen || isExtraSmallScreen) && !isMediumScreen) {
			buttonVariant = 'text';
			size = SIZE.small;
			buttonSize = SIZE.small;
		}
		return {
			buttonVariant,
			size,
			buttonSize,
		};
	};
export { useScreenSize, SIZE, SHORT_SIZE };
