import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, Link, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
import { format, toZonedTime } from 'date-fns-tz';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { ROLESDATA } from '../../../components/aclContainer/roles';
import LogDetails from '../../../components/logDetails';
import { AuthContext } from '../../../context';
import AttachmentModal from '../attachmentModal';
import { DEFAULT_PAGE_SIZE } from '../constants';
import ObservationEntryFormDialog from '../observations/observationEntryFormDialog';
import TimecardEntryFormDialog from '../timecards/timecardEntryFormDialog';

const LogsTable = ({
	data,
	logDataRefreshed = () => {},
	initialPage,
	initialPageSize,
	type, // 'timecard' or 'observation'
}) => {
	const [modalOpen, setModalOpen] = useState(false),
		[selectedAttachments, setSelectedAttachments] = useState([]),
		[editDialogOpen, setEditDialogOpen] = useState(false),
		[editingEntry, setEditingEntry] = useState(null),
		navigate = useNavigate(),
		location = useLocation(),
		{ id } = useParams(),
		{ canAccess, getUserId } = useContext(AuthContext),
		isSuperintendent = canAccess([ROLESDATA.Superintendent.RoleName]),
		currentUserId = getUserId(),
		[windowWidth, setWindowWidth] = useState(window.innerWidth),
		[expandedRows, setExpandedRows] = useState({}),
		[openedEntries, setOpenedEntries] = useState([]),
		[pagination, setPagination] = useState({
			pageIndex: initialPage || 0,
			pageSize: initialPageSize || DEFAULT_PAGE_SIZE,
		}),
		basePath = `/${type}s`, // '/timecards' or '/observations'
		[isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (location.pathname.includes('/attachments') && id && data) {
			const entry = data.find((e) => e.id.toString() === id);
			if (entry?.attachments) {
				setSelectedAttachments(entry.attachments);
				setModalOpen(true);
			}
		}
	}, [id, data, location]);

	useEffect(() => {
		const params = new URLSearchParams(location.search),
			openedParam = params.get('opened'),
			pageParam = parseInt(params.get('page'), 10),
			pageSizeParam = parseInt(params.get('pageSize'), 10);

		if (openedParam) {
			const openedIds = openedParam.split('_'),
				newExpandedState = openedIds.reduce((acc, id) => {
					acc[id] = true;
					return acc;
				}, {});
			setExpandedRows(newExpandedState);
			setOpenedEntries(openedIds);
		}

		if (!isNaN(pageParam) && !isNaN(pageSizeParam)) {
			setPagination({
				pageIndex: pageParam - 1,
				pageSize: pageSizeParam,
			});
		}

		setIsInitialized(true);
	}, [location.search]);

	const handleOpenModal = useCallback(
			(attachments, entryId) => {
				setSelectedAttachments(attachments);
				setModalOpen(true);
				navigate(`${basePath}/${entryId}/attachments`, { replace: true });
			},
			[navigate, basePath]
		),
		handleCloseModal = useCallback(() => {
			setModalOpen(false);
			navigate(basePath, { replace: true });
		}, [navigate, basePath]),
		handleRefresh = useCallback(() => {
			if (typeof logDataRefreshed === 'function') {
				logDataRefreshed(false);
			}
		}, [logDataRefreshed]),
		canEdit = useCallback(
			(entry) => {
				if (canAccess([ROLESDATA.Administrator.RoleName])) return true;
				if (entry.submittedAt) return false;
				if (!entry.submittedAt && entry.user.id === currentUserId) return true;
				return false;
			},
			[canAccess, currentUserId]
		),
		getEditingEntry = useCallback(
			(entry) => {
				if (!entry) return null;

				const currentDateTime = new Date().toISOString();

				if (type === 'observation') {
					return {
						...entry,
						observationDateTime: entry.id ? entry.observationDateTime : currentDateTime,
					};
				}

				return {
					...entry,
					timecardDate: entry.id ? entry.timecardDate : currentDateTime,
				};
			},
			[type]
		),
		handleEdit = useCallback(
			(entry) => {
				setEditingEntry(getEditingEntry(entry));
				setEditDialogOpen(true);
			},
			[getEditingEntry]
		),
		handleCloseEditDialog = useCallback(() => {
			setEditDialogOpen(false);
			setEditingEntry(null);
			if (typeof logDataRefreshed === 'function') {
				logDataRefreshed(false);
			}
		}, [logDataRefreshed]),
		getColumnVisibility = useCallback(() => {
			const baseHidden = {
				isBillable: false,
				submittedOrSavedAt: false,
				submitStatus: false,
			};

			if (windowWidth <= 750) {
				return {
					...baseHidden,
					notes: false,
					hasAttachments: false,
					id: false,
				};
			} else if (windowWidth <= 1200) {
				return {
					...baseHidden,
					notes: false,
				};
			} else {
				return baseHidden;
			}
		}, [windowWidth]),
		getUniqueUsers = useCallback(
			(tableData) => {
				if (!tableData) return [];
				const userSet = new Set(tableData.map((entry) => JSON.stringify(entry.user)));
				return Array.from(userSet)
					.map((user) => JSON.parse(user))
					.filter((user) => user.id !== currentUserId)
					.sort((a, b) => a.name.localeCompare(b.name));
			},
			[currentUserId]
		),
		getUniqueProjects = useCallback((tableData) => {
			if (!tableData) return [];
			const projectSet = new Set(tableData.map((entry) => entry.job?.name).filter(Boolean));
			return Array.from(projectSet).sort((a, b) => a.localeCompare(b));
		}, []),
		updateUrl = useCallback(
			(newPagination, newOpenedEntries) => {
				const params = new URLSearchParams(location.search);
				params.set('page', (newPagination.pageIndex + 1).toString());
				params.set('pageSize', newPagination.pageSize.toString());

				if (newOpenedEntries.length > 0) {
					params.set('opened', newOpenedEntries.join('_'));
				} else {
					params.delete('opened');
				}

				const newUrl = `${location.pathname}?${params.toString()}`;
				window.history.replaceState(null, '', newUrl);
			},
			[location]
		),
		getRowDetailsData = useCallback(
			(entry) => {
				const entryDate = new Date(entry[`${type}Date`] || entry[`${type}DateTime`]),
					column1Data = [
						{ key: 'ID', value: entry.id },
						{
							key: 'Log Date',
							value: format(toZonedTime(entryDate, 'America/Denver'), 'MM-dd-yyyy'),
						},
						{ key: 'Logged By', value: entry.user.name },
						{ key: 'Submitted', value: !!entry.submittedAt, type: 'icon' },
						{
							key: entry.submittedAt ? 'Submitted On' : 'Saved On',
							value: format(
								toZonedTime(entry.submittedAt || entry.updatedAt, 'America/Denver'),
								'MM-dd-yyyy hh:mm a'
							),
						},
					],
					column2Data = [
						{ key: 'Project', value: entry.job?.name || 'N/A' },
						{ key: 'Activity', value: entry.activity.description },
					];

				// Add timecard-specific fields
				if (type === 'timecard') {
					column2Data.push(
						{ key: 'Hours', value: entry.hours || 0 },
						{ key: 'Billable', value: entry.isBillable, type: 'icon' }
					);
				}

				// Add attachments last
				column2Data.push({
					key: 'Attachments',
					value:
						entry.attachments?.length > 0
							? {
									text: `${entry.attachments.length} attachment${
										entry.attachments.length !== 1 ? 's' : ''
									}`,
									onClick: () => handleOpenModal(entry.attachments, entry.id),
								}
							: 'None',
					type: entry.attachments?.length > 0 ? 'link' : 'text',
				});

				return { column1Data, column2Data, notes: entry.notes };
			},
			[type, handleOpenModal]
		),
		renderRowDetails = useCallback(
			(row) => {
				const entry = row.original,
					{ column1Data, column2Data, notes } = getRowDetailsData(entry);

				return (
					<LogDetails
						title={`${type.charAt(0).toUpperCase() + type.slice(1)} Details`}
						column1Data={column1Data}
						column2Data={column2Data}
						notes={notes}
						onEdit={() => handleEdit(entry)}
						canEdit={canEdit(entry)}
					/>
				);
			},
			[type, handleEdit, canEdit, getRowDetailsData]
		),
		handleRowExpand = useCallback(
			(updater) => {
				let newExpandedState;
				if (typeof updater === 'function') {
					newExpandedState = updater(expandedRows);
				} else {
					newExpandedState = updater;
				}

				setExpandedRows(newExpandedState);
				const newOpenedEntries = Object.keys(newExpandedState).filter((id) => newExpandedState[id]);
				setOpenedEntries(newOpenedEntries);
				updateUrl(pagination, newOpenedEntries);
			},
			[updateUrl, pagination, expandedRows]
		),
		baseColumns = useMemo(() => {
			const dropdownStyles = {
					'& .MuiSelect-select': {
						fontSize: '0.8rem',
						paddingTop: '0.5rem',
						paddingBottom: '0.5rem',
					},
					'& .MuiOutlinedInput-notchedOutline': {
						top: 0,
					},
					'& legend': {
						display: 'none',
					},
				},
				commonColumns = [
					{
						accessorKey: 'id',
						header: 'ID',
						size: 30,
					},
					{
						id: 'submitStatus',
						accessorFn: (row) => (row.submittedAt ? 1 : 0),
						header: 'Submitted',
						size: 50,
						Cell: ({ cell }) => (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								{cell.getValue() ? (
									<CheckIcon sx={{ color: 'success.main' }} />
								) : (
									<CloseIcon sx={{ color: 'error.main' }} />
								)}
							</Box>
						),
					},
					{
						id: 'submittedOrSavedAt',
						accessorFn: (row) => new Date(row.submittedAt || row.updatedAt),
						filterVariant: 'date-range',
						header: 'Saved/Submit On',
						size: 75,
						Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
					},
					{
						id: `${type}Date`,
						// DateTime for observation column name
						accessorFn: (row) => new Date(row[`${type}Date`] || row[`${type}DateTime`]),
						header: 'Log Date',
						size: 75,
						filterVariant: 'date-range',
						Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
					},
					{
						id: 'user',
						header: 'Logged By',
						size: 75,
						accessorFn: (row) => ({
							id: row.user.id,
							name: row.user.name,
						}),
						filterFn: (row, columnId, filterValue) => {
							if (!filterValue || filterValue.length === 0) return true;
							return filterValue.includes(row.getValue(columnId).id);
						},
						Cell: ({ cell }) => cell.getValue().name,
						Filter: ({ column }) => {
							const uniqueUsers = getUniqueUsers(data);

							return (
								<FormControl variant="outlined" size="small" sx={{ width: 200 }}>
									<Select
										multiple
										value={column.getFilterValue() ?? []}
										onChange={(e) => column.setFilterValue(e.target.value)}
										displayEmpty
										sx={dropdownStyles}
										renderValue={(selected) => {
											if (!selected || selected.length === 0) return 'All Users';
											if (selected.length === 1) {
												const user = [currentUserId, ...uniqueUsers.map((u) => u.id)].includes(
													selected[0]
												)
													? selected[0] === currentUserId
														? 'Current User'
														: uniqueUsers.find((u) => u.id === selected[0])?.name
													: 'Unknown User';
												return user;
											}
											return `${selected.length} users selected`;
										}}
									>
										<MenuItem value={currentUserId}>Current User</MenuItem>
										{uniqueUsers.map((user) => (
											<MenuItem key={user.id} value={user.id}>
												{user.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							);
						},
					},
					{
						id: 'project',
						accessorFn: (row) => row.job?.name ?? '-',
						header: 'Project',
						size: 75,
						filterFn: 'equals',
						Filter: ({ column }) => (
							<FormControl variant="outlined" size="small" sx={{ width: 150 }}>
								<Select
									value={column.getFilterValue() || ''}
									onChange={(e) => column.setFilterValue(e.target.value)}
									displayEmpty
									sx={dropdownStyles}
								>
									<MenuItem value="">All Projects</MenuItem>
									{getUniqueProjects(data).map((projectName) => (
										<MenuItem key={projectName} value={projectName}>
											{projectName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						),
					},
					{
						accessorKey: 'activity.description',
						header: 'Activity',
						size: 75,
					},
				];

			// Insert timecard-specific columns after Activity
			if (type === 'timecard') {
				commonColumns.push(
					{
						accessorKey: 'hours',
						header: 'Hours',
						size: 55,
						Cell: ({ cell }) => (cell.row.original.hours ? cell.row.original.hours : 0),
					},
					{
						accessorKey: 'isBillable',
						header: 'Billable',
						size: 55,
						Cell: ({ cell }) => (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								{cell.getValue() ? <CheckIcon /> : <CloseIcon />}
							</Box>
						),
					}
				);
			}

			// Add remaining common columns
			commonColumns.push(
				{
					accessorKey: 'notes',
					header: 'Notes',
					size: 200,
					Cell: ({ cell }) => {
						const notes = cell.getValue();
						if (!notes) return '-';
						return notes.length > 50 ? `${notes.substring(0, 50)}...` : notes;
					},
				},
				{
					id: 'hasAttachments',
					header: 'Attachments',
					size: 75,
					accessorFn: (row) => row.attachments?.length || 0,
					Cell: ({ row }) => {
						const attachments = row.original.attachments;
						if (attachments?.length > 0) {
							return (
								<Link component="button" onClick={() => handleOpenModal(attachments, row.original.id)}>
									{`${attachments.length} attachment${attachments.length !== 1 ? 's' : ''}`}
								</Link>
							);
						}
						return '-';
					},
				}
			);

			return commonColumns;
		}, [type, getUniqueUsers, data, currentUserId, getUniqueProjects, handleOpenModal]),
		table = useMaterialReactTable({
			columns: baseColumns,
			data: data || [],
			initialState: {
				sorting: [{ id: `${type}Date`, desc: true }],
				density: 'compact',
				showColumnFilters: isSuperintendent,
				pagination: { pageSize: DEFAULT_PAGE_SIZE },
				columnFilters: isSuperintendent ? [{ id: 'user', value: [currentUserId] }] : [],
				columnVisibility: getColumnVisibility(),
			},
			muiTableBodyRowProps: ({ row }) => ({
				onClick: (event) => {
					const isEditButton = event.target.closest('button[title="Edit"]'),
						isAttachmentLink = event.target.closest('a'),
						isExpanderButton = event.target.closest('button');
					if (!isEditButton && !isAttachmentLink && !isExpanderButton) {
						row.toggleExpanded();
					}
				},
				sx: {
					cursor: 'pointer',
					backgroundColor: row.original?.submittedAt ? 'white' : '#fbf9ea',
					'&:hover': {
						backgroundColor: row.original?.submittedAt ? '#f5f5f5' : '#f5f3d7',
					},
				},
			}),
			enableDensityToggle: false,
			enableFullScreenToggle: false,
			renderTopToolbarCustomActions: () => (
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<IconButton onClick={handleRefresh} title="Refresh">
						<RefreshIcon />
					</IconButton>
					<IconButton onClick={handleResetTable} title="Restore table defaults">
						<FilterAltOffIcon />
					</IconButton>
				</Box>
			),
			renderDetailPanel: ({ row }) => (
				<Box sx={{ p: 2 }} onClick={(e) => e.stopPropagation()}>
					{renderRowDetails(row)}
				</Box>
			),
			enableExpanding: true,
			enableStickyHeader: true,
			enableStickyFooter: true,
			muiTableContainerProps: {
				sx: {
					maxHeight: 'calc(100vh - 275px)',
					visibility: isInitialized ? 'visible' : 'hidden',
				},
			},
			getRowId: (row) => row.id,
			state: {
				expanded: expandedRows,
				pagination,
			},
			onPaginationChange: (updater) => {
				const newPagination = updater(pagination);
				setPagination(newPagination);
				updateUrl(newPagination, openedEntries);
			},
			onExpandedChange: handleRowExpand,
		}),
		handleResetTable = useCallback(() => {
			table.resetColumnFilters();
			table.resetSorting();
			table.resetColumnVisibility();
			setExpandedRows({});
			setOpenedEntries([]);
			const newPagination = { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE };
			setPagination(newPagination);
			updateUrl(newPagination, []);
		}, [table, updateUrl]),
		handleEntryDeleted = useCallback(
			(deletedEntryId) => {
				// Remove the deleted timecard/observation from expanded rows
				const newExpandedRows = { ...expandedRows };
				delete newExpandedRows[deletedEntryId];
				setExpandedRows(newExpandedRows);

				// Remove from opened timecards/observations
				const newOpenedEntries = openedEntries.filter((id) => id !== deletedEntryId.toString());
				setOpenedEntries(newOpenedEntries);

				// Update URL without the deleted timecard/observation
				updateUrl(pagination, newOpenedEntries);
			},
			[expandedRows, openedEntries, pagination, updateUrl]
		);

	if (!data) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box sx={{ padding: 2, position: 'relative' }}>
			{!isInitialized && (
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'rgba(255, 255, 255, 0.7)',
						zIndex: 1,
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<MaterialReactTable table={table} />
			<AttachmentModal open={modalOpen} handleClose={handleCloseModal} attachments={selectedAttachments} />
			{type === 'timecard' ? (
				<TimecardEntryFormDialog
					open={editDialogOpen}
					onClose={handleCloseEditDialog}
					timecardDataRefreshed={logDataRefreshed}
					editingTimecard={editingEntry}
					onTimecardDeleted={handleEntryDeleted}
				/>
			) : (
				<ObservationEntryFormDialog
					open={editDialogOpen}
					onClose={handleCloseEditDialog}
					observationDataRefreshed={logDataRefreshed}
					editingObservation={editingEntry}
					onObservationDeleted={handleEntryDeleted}
				/>
			)}
		</Box>
	);
};

export default LogsTable;
