import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useSnackbar } from 'notistack';

import UserAdminFormDialog from './userAdminFormDialog';
import { ROLESDATA } from '../../components/aclContainer/roles';
import AppContainer from '../../components/appContainer';
import { LoadingContext, AuthContext } from '../../context';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { useScreenSize, SIZE } from '../../hooks/useScreenSize';
import { logger } from '../../utils/loggingUtils';

export default function UserAdmin() {
	const [users, setUsers] = useState([]),
		[isUsersLoaded, setIsUsersLoaded] = useState(false),
		[isFetchingUsers, setIsFetchingUsers] = useState(false),
		{ startLoading, stopLoading } = useContext(LoadingContext),
		{ httpGet, httpPut, httpDelete } = useFetchWithMsal(),
		{ size } = useScreenSize(),
		{ enqueueSnackbar } = useSnackbar(),
		{ canAccess } = useContext(AuthContext),
		isAdmin = canAccess([ROLESDATA.Administrator.RoleName]),
		handleCreateRowSave = ({ values, table }) => {
			const userInfo = buildUserInfo(values);
			userInfo.username = values.email;
			handleSave(userInfo, values, table, true);
		},
		handleEditingRowSave = ({ values, table, row }) => {
			const userInfo = buildUserInfo(values);
			userInfo.id = row.id;
			handleSave(userInfo, values, table, false);
		},
		buildUserInfo = (values) => {
			const { firstName, lastName, email, isActive } = values,
				userInfo = {
					firstName,
					lastName,
					name: `${firstName} ${lastName}`,
					email,
					isActive: isActive === undefined ? true : isActive,
				};
			return userInfo;
		},
		handleSave = (userInfo, values, table, isCreating) => {
			const { newRoles } = values,
				roleNames = [];
			newRoles?.forEach((r) => {
				roleNames.push(r.roleName);
			});
			userInfo.roles = roleNames;
			startLoading();
			httpPut({
				urlSubPath: 'user',
				body: userInfo,
			})
				.then(() => {
					isCreating ? table.setCreatingRow(null) : table.setEditingRow(null);
					setIsUsersLoaded(false);
				})
				.catch((error) => {
					logger.error(error);
					enqueueSnackbar(`Unable to save user. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					setIsUsersLoaded(false);
				});
		},
		handleClickEditRow = (table, row) => {
			table.setEditingRow(row);
		},
		openDeleteConfirmModal = (table, row) => {
			if (window.confirm('Are you sure you want to delete this user?')) {
				handleClickDeleteRow(row.original.id);
			}
		},
		handleClickDeleteRow = (id) => {
			startLoading();
			httpDelete({
				urlSubPath: `user/${id}`,
			})
				.then(() => {
					loadUsers();
				})
				.catch((error) => {
					enqueueSnackbar(`Unable to delete user. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					setIsUsersLoaded(false);
				});
		},
		loadUsers = useCallback(() => {
			if (isFetchingUsers) return;
			setIsFetchingUsers(true);
			startLoading();
			httpGet({
				urlSubPath: 'users',
			})
				.then((response) => {
					setUsers(response);
					setIsUsersLoaded(true);
				})
				.catch((error) => {
					logger.error(error);
					enqueueSnackbar(`Unable to load users. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					setIsFetchingUsers(false);
					stopLoading();
				});
		}, [enqueueSnackbar, httpGet, startLoading, stopLoading, isFetchingUsers]),
		columns = useMemo(
			() => [
				{
					accessorKey: 'firstName',
					header: 'First name',
				},
				{
					accessorKey: 'lastName',
					header: 'Last name',
				},
				{
					accessorKey: 'email',
					header: 'Email',
				},
				{
					Cell: ({ cell }) => {
						const roles = cell.getValue(),
							display = (roles && roles.map((r) => r.roleDescription).join(', ')) || '';
						return <span>{display}</span>;
					},
					accessorKey: 'roles',
					header: 'Roles',
					filterVariant: 'multi-select',
					filterSelectOptions: [
						ROLESDATA.Administrator.RoleDescription,
						ROLESDATA.ProjectManager.RoleDescription,
						ROLESDATA.Superintendent.RoleDescription,
						ROLESDATA.Installer.RoleDescription,
					],
					filterFn: (row, id, filterValues) => {
						if (!filterValues || filterValues.length === 0) return true;
						const roles = row.original[id];
						let role = roles.find((r) => filterValues.includes(r.roleDescription));
						return role !== undefined;
					},
				},
				{
					accessorKey: 'isActive',
					header: 'Active',
					filterVariant: 'checkbox',
					size: size === SIZE.small ? 80 : 100,
					Cell: ({ cell }) => {
						return cell.getValue() === true ? <CheckIcon /> : <CloseIcon />;
					},
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
				},
			],
			[size]
		),
		table = useMaterialReactTable({
			columns,
			data: users,
			createDisplayMode: 'modal',
			editDisplayMode: 'modal',
			enableEditing: isAdmin,
			enableGlobalFilter: false,
			enableFullScreenToggle: false,
			enableStickyHeader: true,
			enableStickyFooter: true,
			getRowId: (row) => row.id,
			initialState: {
				pagination: { pageSize: 50 },
			},
			muiTableContainerProps: {
				sx: {
					minHeight: '500px',
					maxHeight: 'calc(100vh - 225px)',
				},
			},
			onCreatingRowSave: (params) => handleCreateRowSave(params),
			onEditingRowSave: handleEditingRowSave,
			renderRowActions: ({ row, table }) =>
				isAdmin ? (
					<Box sx={{ display: 'flex', gap: '0.7rem' }}>
						<Tooltip title="Edit">
							<IconButton onClick={() => handleClickEditRow(table, row)}>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete">
							<IconButton color="error" onClick={() => openDeleteConfirmModal(table, row)}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Box>
				) : null,
			renderTopToolbarCustomActions: ({ table }) =>
				isAdmin ? (
					<Button
						variant="contained"
						onClick={() => {
							table.setCreatingRow(true);
						}}
					>
						Create User
					</Button>
				) : null,
			renderCreateRowDialogContent: ({ table, row }) => <UserAdminFormDialog table={table} row={row} />,
			renderEditRowDialogContent: ({ table, row }) => <UserAdminFormDialog table={table} row={row} />,
			muiCreateRowDialogProps: {
				fullScreen: true,
			},
			muiEditRowDialogProps: {
				fullScreen: true,
			},
		});
	useEffect(() => {
		if (!isUsersLoaded && !isFetchingUsers) {
			loadUsers();
		}
	}, [isUsersLoaded, isFetchingUsers, loadUsers]);

	return (
		<AppContainer>
			<MaterialReactTable table={table} />
		</AppContainer>
	);
}
