import React from 'react';

import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useFetchWithMsal from '../../../hooks/useFetchWithMsal';

export default function ChildLocationsManager({ project, locationId }) {
	const [childLocations] = project.locations,
		navigate = useNavigate(),
		{ httpPost, httpPut, httpDelete } = useFetchWithMsal(),
		handleLocationClick = (locId) => {
			navigate(`/projects/${project.id}/locations/${locId}`);
		},
		handleAddLocation = async (newLocation) => {
			try {
				await httpPost({
					urlSubPath: `projects/${project.id}/locations${locationId ? `/${locationId}` : ''}`,
					body: newLocation,
				});
			} catch (error) {
				console.error('Error adding location:', error);
			}
		},
		handleEditLocation = async (locId, updatedLocation) => {
			try {
				await httpPut({
					urlSubPath: `projects/${project.id}/locations/${locId}`,
					body: updatedLocation,
				});
			} catch (error) {
				console.error('Error updating location:', error);
			}
		},
		handleDeleteLocation = async (locId) => {
			try {
				await httpDelete({ urlSubPath: `projects/${project.id}/locations/${locId}` });
			} catch (error) {
				console.error('Error deleting location:', error);
			}
		};

	return (
		<Box sx={{ mt: 2 }}>
			<Typography variant="h6">Locations Management</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!childLocations || childLocations.length === 0 ? (
							<TableRow>
								<TableCell colSpan={3} align="center">
									<Typography variant="body2" color="text.secondary">
										No child locations found. Add a new location to get started.
									</Typography>
								</TableCell>
							</TableRow>
						) : (
							childLocations.map((location) => (
								<TableRow
									key={location.id}
									onClick={() => handleLocationClick(location.id)}
									style={{ cursor: 'pointer' }}
								>
									<TableCell>{location.name}</TableCell>
									<TableCell>{location.type}</TableCell>
									<TableCell>
										<IconButton
											onClick={(e) => {
												e.stopPropagation();
												handleEditLocation(location.id, {
													/* updated data */
												});
											}}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											onClick={(e) => {
												e.stopPropagation();
												handleDeleteLocation(location.id);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				startIcon={<AddIcon />}
				onClick={() =>
					handleAddLocation({
						/* new location data */
					})
				}
			>
				Add Location
			</Button>
		</Box>
	);
}
