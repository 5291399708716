import React, { useContext } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AuthContext } from '../../context';

export const AclContainer = ({ allowedRoles, isAnyRole }) => {
	const location = useLocation(),
		{ isActive, roles, isAuthorizingUser } = useContext(AuthContext);

	let content = <Outlet />;
	if (isAuthorizingUser()) {
		return content;
	}
	const hasSomeRole = roles.some((role) => {
		for (const index in allowedRoles) {
			const roleName = allowedRoles[index];
			if (role.roleName === roleName) {
				return true;
			}
		}
		return false;
	});

	content =
		(isActive && isAnyRole && roles && roles.length > 0) || hasSomeRole ? (
			<Outlet />
		) : (
			<Navigate to="/401" state={{ from: location }} replace />
		);
	return content;
};

export default AclContainer;
