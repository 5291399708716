import React from 'react';

import LogsTable from '../logsTable';

const Observations = ({ observationData, observationDataRefreshed, initialPage, initialPageSize }) => {
	return (
		<LogsTable
			data={observationData}
			logDataRefreshed={observationDataRefreshed}
			initialPage={initialPage}
			initialPageSize={initialPageSize}
			type="observation"
		/>
	);
};

export default Observations;
