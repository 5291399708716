import React, { useContext, useState, useCallback, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Stack,
	Typography,
	ListItem,
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	TextField,
	List,
	ListItemText,
	IconButton,
	Link,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import FileUploadInput from '../../../../components/fileUploadInput';
import { AuthContext } from '../../../../context';
import { LoadingContext } from '../../../../context/loading';
import { useBlobService } from '../../../../hooks/useBlobService';
import useFetchWithMsal from '../../../../hooks/useFetchWithMsal';
import { SIZE } from '../../../../hooks/useScreenSize';
import { EntryObservationRequest } from '../../constants';

import './style.css';

function ObservationEntryFormDialog(props) {
	const { open, onClose, observationDataRefreshed, editingObservation, onObservationDeleted } = props,
		{ httpGet, httpPost, httpPut, httpDelete } = useFetchWithMsal(),
		{ enqueueSnackbar } = useSnackbar(),
		{ startLoading, stopLoading } = useContext(LoadingContext),
		{ getUserId, isAdmin } = useContext(AuthContext),
		{ uploadFiles } = useBlobService(),
		// PROJECTS
		[projectOptions, setProjectOptions] = useState([]),
		[selectedProject, setSelectedProject] = useState(null),
		[selectedFiles, setSelectedFiles] = useState([]),
		[existingAttachments, setExistingAttachments] = useState([]),
		loadProjectOptions = useCallback(() => {
			httpGet({ urlSubPath: 'job' })
				.then(setProjectOptions)
				.catch((error) =>
					enqueueSnackbar(`Unable to load projects. ${error}`, { variant: 'error', preventDuplicate: true })
				);
		}, [enqueueSnackbar, httpGet]),
		handleProjectChange = (newProject) => {
			if (newProject && newProject.id !== selectedProject?.id) {
				setSelectedProject(newProject);
			}
		},
		// DATE
		[selectedDateTime, setSelectedDateTime] = useState(dayjs()),
		handleDateTimeChange = (newDateTime) => {
			setSelectedDateTime(newDateTime);
		},
		// ACTIVITY
		[activityOptions, setActivityOptions] = useState([]),
		[selectedActivity, setSelectedActivity] = useState(null),
		loadActivityOptions = useCallback(() => {
			httpGet({ urlSubPath: 'activities', queryParams: { isObservationRelated: true } })
				.then(setActivityOptions)
				.catch((error) =>
					enqueueSnackbar(`Unable to load activities. ${error}`, { variant: 'error', preventDuplicate: true })
				);
		}, [enqueueSnackbar, httpGet]),
		handleActivityChange = (newActivity) => {
			if (newActivity && newActivity.id !== selectedActivity?.id) {
				setSelectedActivity(newActivity);
			}
		},
		// NOTES
		[selectedNotes, setSelectedNotes] = useState(''),
		handleNotesChange = (event) => {
			const newValue = event.currentTarget.value;
			setSelectedNotes(newValue);
		},
		// OBSERVATION
		[id, setId] = useState(null),
		[hasLoadedOptions, setHasLoadedOptions] = useState(false),
		resetObservationForm = () => {
			setId(null);
			setSelectedDateTime(dayjs());
			setSelectedProject(null);
			setSelectedActivity(null);
			setSelectedNotes('');
			setSelectedFiles([]);
			setExistingAttachments([]);
		},
		[openConfirmationDialog, setOpenConfirmationDialog] = useState(false),
		handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false),
		handleFinishSubmit = () => {
			handleCloseConfirmationDialog();
			resetObservationForm();
			onClose();
		},
		handleSave = async (isSubmit = false) => {
			startLoading();
			try {
				let postUploadAttachments = [...existingAttachments];
				if (selectedFiles.length > 0) {
					const uploadedFiles = await uploadFiles(selectedFiles);
					postUploadAttachments = [
						...postUploadAttachments,
						...uploadedFiles.map((file) => ({
							filename: file.originalFilename,
							uri: file.url,
							bucket: file.bucket,
						})),
					];
				}

				const userId = getUserId(),
					request = new EntryObservationRequest({
						id,
						observationDateTime: selectedDateTime.toISOString(),
						jobId: selectedProject?.id ?? null,
						activityId: selectedActivity.id,
						userId,
						notes: selectedNotes,
						attachments: postUploadAttachments,
						isSubmitted: isSubmit,
					});

				let response;
				if (id) {
					response = await httpPut({ urlSubPath: `observations/${id}`, body: request.getBody() });
				} else {
					response = await httpPost({ urlSubPath: 'observations', body: request.getBody() });
				}

				const { activity, observationDateTime, job } = response,
					parsedDate = dayjs(observationDateTime).format('YYYY-MM-DD HH:mm:ss');
				let message = `${isSubmit ? 'Submitted' : id ? 'Updated' : 'Saved'} ${
					activity.description
				} observation for date ${parsedDate}`;
				if (job) {
					message += ` and project ${job.name}`;
				}
				enqueueSnackbar(message, {
					variant: 'info',
					preventDuplicate: true,
					autoHideDuration: 5000,
				});
				handleFinishSubmit();
			} catch (error) {
				enqueueSnackbar(`Unable to ${isSubmit ? 'submit' : id ? 'update' : 'save'} observation. ${error}`, {
					variant: 'error',
					preventDuplicate: true,
				});
			} finally {
				stopLoading();
				observationDataRefreshed(false);
			}
		},
		handleFileChange = (files) => {
			setSelectedFiles(files);
		},
		handleDeleteAttachment = (attachmentId) => {
			setExistingAttachments(existingAttachments.filter((attachment) => attachment.id !== attachmentId));
		},
		onExit = () => {
			resetObservationForm();
			onClose();
		},
		onSave = async () => {
			await handleSave(false);
		},
		onSubmit = async () => {
			setOpenConfirmationDialog(true);
		},
		handleOkConfirmationDialog = async () => {
			await handleSave(true);
		},
		// SAS TOKEN - Downloadable attachments
		[sasToken, setSasToken] = useState(null),
		getSasToken = useCallback(async () => {
			try {
				const { sasToken } = await httpGet({
					urlSubPath: 'sas-token',
					queryParams: { containerName: process.env.REACT_APP_ABS_ATTACHMENTS_CONTAINER_NAME },
				});
				setSasToken(sasToken);
			} catch (error) {
				console.error('Error fetching SAS token:', error);
			}
		}, [httpGet]),
		getAuthenticatedUri = (uri) => {
			return sasToken ? `${uri}?${sasToken}` : uri;
		},
		handleDownload = async (attachment) => {
			const authenticatedUri = getAuthenticatedUri(attachment.uri);
			try {
				const response = await fetch(authenticatedUri),
					blob = await response.blob(),
					url = window.URL.createObjectURL(blob),
					link = document.createElement('a');
				link.href = url;
				link.download = attachment.filename;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error('Error downloading file:', error);
				enqueueSnackbar(`Error downloading file: ${error.message}`, { variant: 'error' });
			}
		};

	// EFFECTS
	useEffect(() => {
		// Initial data load for all attributes
		if (open && !hasLoadedOptions) {
			loadActivityOptions();
			loadProjectOptions();
			setHasLoadedOptions(true);
		}

		if (!open) {
			setHasLoadedOptions(false);
		}
	}, [open, hasLoadedOptions, loadActivityOptions, loadProjectOptions]);

	useEffect(() => {
		if (editingObservation) {
			setId(editingObservation.id);
			setSelectedDateTime(dayjs(editingObservation.observationDateTime));
			setSelectedProject(editingObservation.job);
			setSelectedActivity(editingObservation.activity);
			setSelectedNotes(editingObservation.notes);
			setExistingAttachments(editingObservation.attachments || []);
		} else {
			resetObservationForm();
		}
	}, [editingObservation]);

	// NEW: Effect for fetching SAS token
	useEffect(() => {
		if (open && existingAttachments.length > 0) {
			getSasToken();
		}
	}, [open, existingAttachments, getSasToken]);

	const handleDeleteObservation = async () => {
		if (!window.confirm('Are you sure you want to delete this observation? This action cannot be undone.')) {
			return;
		}

		startLoading();
		try {
			await httpDelete({ urlSubPath: `observations/${id}` });
			enqueueSnackbar('Observation deleted successfully', {
				variant: 'success',
				preventDuplicate: true,
			});
			observationDataRefreshed(false);
			onObservationDeleted?.(id);
			onClose();
		} catch (error) {
			enqueueSnackbar(`Unable to delete observation. ${error}`, {
				variant: 'error',
				preventDuplicate: true,
			});
		} finally {
			stopLoading();
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullScreen fullWidth maxWidth="md" scroll="paper">
			<DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				{id ? 'Edit Observation' : 'Add Observation'}
			</DialogTitle>
			<DialogContent dividers sx={{ padding: 1 }}>
				<Stack spacing={2}>
					<FormControl fullWidth>
						<DateTimePicker
							required
							value={selectedDateTime}
							onChange={handleDateTimeChange}
							label="Log Date & Time"
						/>
					</FormControl>
					<FormControl fullWidth>
						<Autocomplete
							disablePortal
							size={SIZE.small}
							fullWidth
							options={projectOptions}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={selectedProject}
							onChange={(event, newValue) => handleProjectChange(newValue)}
							renderInput={(params) => <TextField required {...params} label="Project" />}
						/>
					</FormControl>
					<FormControl fullWidth>
						<Autocomplete
							disablePortal
							size={SIZE.small}
							fullWidth
							options={activityOptions}
							getOptionLabel={(option) => option.description}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={selectedActivity}
							onChange={(event, newValue) => handleActivityChange(newValue)}
							renderInput={(params) => <TextField required {...params} label="Activity" />}
						/>
					</FormControl>
					<FormControl fullWidth>
						<TextField
							fullWidth
							multiline
							rows={3}
							label="Notes"
							value={selectedNotes}
							onChange={handleNotesChange}
						/>
					</FormControl>
					<FileUploadInput
						selectedFiles={selectedFiles}
						onFileChange={handleFileChange}
						buttonText="Attach files to observation"
						allowMultiple={true}
						acceptedFileTypes={['.pdf', '.doc', '.docx', '.jpg', '.png']}
						maxFileSize={50 * 1024 * 1024} // 50 MB
						maxFiles={50}
						showFileList={true}
						fileListStyle={{ color: 'text.secondary' }}
					/>
					{existingAttachments.length > 0 && (
						<List>
							<Typography variant="subtitle1">Existing Attachments:</Typography>
							{existingAttachments.map((attachment) => (
								<ListItem
									key={attachment.id}
									secondaryAction={
										<IconButton
											edge="end"
											aria-label="delete"
											onClick={() => handleDeleteAttachment(attachment.id)}
										>
											<DeleteIcon />
										</IconButton>
									}
								>
									<ListItemText
										primary={
											<Link component="button" onClick={() => handleDownload(attachment)}>
												{attachment.filename}
											</Link>
										}
									/>{' '}
								</ListItem>
							))}
						</List>
					)}
					{isAdmin && id && (
						<Button
							onClick={handleDeleteObservation}
							variant="contained"
							color="error"
							sx={{ mt: 2, width: '200px' }}
						>
							Delete Observation
						</Button>
					)}
				</Stack>
			</DialogContent>
			<Dialog title="Confirm Observation Submission" open={openConfirmationDialog}>
				<DialogTitle align="center">Confirm Observation Submission</DialogTitle>
				<Stack>
					<ListItem divider />
					<DialogContent align="center">
						<Typography align="center" variant="body1" sx={{ fontWeight: 'bold' }}>
							Submit Observation?
						</Typography>
						<Typography variant="body2">You will not be able to change it after submission</Typography>
					</DialogContent>
					<ListItem divider />
					<DialogActions>
						<Button onClick={handleCloseConfirmationDialog}>No</Button>
						<Button onClick={handleOkConfirmationDialog} color="primary" variant="contained">
							Yes
						</Button>
					</DialogActions>
				</Stack>
			</Dialog>
			<DialogActions>
				<Button onClick={onExit}>Exit</Button>
				<Button onClick={onSave} color="primary">
					Save
				</Button>
				<Button onClick={onSubmit} variant="contained">
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ObservationEntryFormDialog;
