import React, { useMemo, useState } from 'react';

import { FormGroup, FormLabel, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ROLESDATA } from '../../../components/aclContainer';
import GridEditFormDialog from '../../../components/gridEditFormDialog';
import { SIZE } from '../../../hooks/useScreenSize';

function UserAdminFormDialog(props) {
	const { table, row } = props,
		[validationErrors, setValidationErrors] = useState({}),
		[firstName, setFirstName] = useState(''),
		[lastName, setLastName] = useState(''),
		[email, setEmail] = useState(''),
		[isActive, setIsActive] = useState(true),
		[roles, setRoles] = useState({
			superintendent: false,
			project_manager: false,
			administrator: false,
			installer: false,
		}),
		{ superintendent, project_manager, administrator, installer } = roles,
		handleChangeRole = (event) => {
			let targetName = event.target.name,
				cachedRoles = row._valuesCache['newRoles'] || [];
			setRoles({
				...roles,
				[targetName]: event.target.checked,
			});
			let foundIndex = -1,
				foundRole;
			for (const [k, v] of cachedRoles.entries()) {
				if (v.roleName === targetName) {
					foundIndex = k;
					foundRole = v;
				}
			}
			if (event.target.checked && foundIndex < 0) {
				cachedRoles.push({ roleName: targetName });
			} else if (!event.target.checked && foundIndex >= 0) {
				cachedRoles = cachedRoles.filter((r) => r !== foundRole);
			}
			row._valuesCache['newRoles'] = cachedRoles;
		},
		validateEmail = () =>
			!!email.length &&
			email.toLowerCase().match(
				// eslint-disable-next-line max-len
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			),
		onValidate = (errors) => {
			setValidationErrors(errors);
		},
		// eslint-disable-next-line no-unused-vars
		updateStates = useMemo(() => {
			const firstName = row._valuesCache['firstName'],
				lastName = row._valuesCache['lastName'],
				email = row._valuesCache['email'],
				isActive = row._valuesCache['isActive'],
				newRoles = row._valuesCache['newRoles'],
				cachedRoles = newRoles || row._valuesCache['roles'];

			let superintendentRole = false,
				projectManagerRole = false,
				administratorRole = false,
				installerRole = false;
			if (firstName !== undefined) setFirstName(firstName);
			if (lastName !== undefined) setLastName(lastName);
			if (email !== undefined) setEmail(email);
			if (isActive !== undefined) setIsActive(isActive);
			if (cachedRoles !== undefined) {
				cachedRoles?.forEach((r) => {
					if (r.roleName === ROLESDATA.Superintendent.RoleName) {
						superintendentRole = true;
					}
					if (r.roleName === ROLESDATA.ProjectManager.RoleName) {
						projectManagerRole = true;
					}
					if (r.roleName === ROLESDATA.Administrator.RoleName) {
						administratorRole = true;
					}
					if (r.roleName === ROLESDATA.Installer.RoleName) {
						installerRole = true;
					}
				});
			}
			setRoles({
				superintendent: superintendentRole,
				project_manager: projectManagerRole,
				administrator: administratorRole,
				installer: installerRole,
			});
			if (!newRoles || newRoles.length === 0) {
				row._valuesCache['newRoles'] = cachedRoles?.map((r) => {
					return { roleName: r.roleName };
				});
			}
			if (row._validationErrors) setValidationErrors(row._validationErrors);
		}, [row._validationErrors, row._valuesCache]);
	return (
		<GridEditFormDialog
			table={table}
			row={row}
			objectType="User"
			requiredFieldMap={{ firstName: 'First name', lastName: 'Last name', email: 'Email' }}
			onValidate={onValidate}
			validateFields={() => {
				const errors = {};
				if (!firstName) errors.firstName = 'First Name is Required';
				if (!lastName) errors.lastName = 'Last Name is Required';
				if (!validateEmail()) errors.email = 'Incorrect Email Format';
				return errors;
			}}
		>
			<TextField
				label="First Name"
				required
				size={SIZE.small ? SIZE.small : SIZE.medium}
				value={firstName}
				fullWidth
				error={!!validationErrors?.firstName}
				helperText={validationErrors?.firstName}
				onFocus={() =>
					setValidationErrors({
						...validationErrors,
						firstName: undefined,
					})
				}
				onChange={(event) => {
					const newValue = event.currentTarget.value;
					setFirstName(newValue);
					row._valuesCache['firstName'] = newValue;
				}}
			/>
			<TextField
				label="Last Name"
				required
				size={SIZE.small ? SIZE.small : SIZE.medium}
				value={lastName}
				fullWidth
				sx={{ marginTop: 2 }}
				error={!!validationErrors?.lastName}
				helperText={validationErrors?.lastName}
				onFocus={() =>
					setValidationErrors({
						...validationErrors,
						lastName: undefined,
					})
				}
				onChange={(event) => {
					const newValue = event.currentTarget.value;
					setLastName(newValue);
					row._valuesCache['lastName'] = newValue;
				}}
			/>
			<TextField
				label="Email"
				required
				size={SIZE.small ? SIZE.small : SIZE.medium}
				value={email}
				fullWidth
				sx={{ marginTop: 2 }}
				error={!!validationErrors?.email}
				helperText={validationErrors?.email}
				onFocus={() =>
					setValidationErrors({
						...validationErrors,
						email: undefined,
					})
				}
				onChange={(event) => {
					const newValue = event.currentTarget.value;
					setEmail(newValue);
					row._valuesCache['email'] = newValue;
				}}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={isActive}
						onChange={(event, newValue) => {
							setIsActive(newValue);
							row._valuesCache['isActive'] = newValue;
						}}
					/>
				}
				label="Active"
			/>
			<FormLabel component="legend" className="label">
				Roles
			</FormLabel>
			<FormGroup className="label">
				<FormControlLabel
					control={<Checkbox name="administrator" checked={administrator} onChange={handleChangeRole} />}
					label="Administrator"
				/>
				<FormControlLabel
					control={<Checkbox name="project_manager" checked={project_manager} onChange={handleChangeRole} />}
					label="Project Manager"
				/>
				<FormControlLabel
					control={<Checkbox name="superintendent" checked={superintendent} onChange={handleChangeRole} />}
					label="Superintendent"
				/>
				<FormControlLabel
					control={<Checkbox name="installer" checked={installer} onChange={handleChangeRole} />}
					label="Installer"
				/>
			</FormGroup>
		</GridEditFormDialog>
	);
}

export default UserAdminFormDialog;
