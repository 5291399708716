import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useSnackbar } from 'notistack';

import { ROLESDATA } from '../../../components/aclContainer/roles';
import AppContainer from '../../../components/appContainer';
import { LoadingContext, AuthContext } from '../../../context';
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';
import { logger } from '../../../utils/loggingUtils';

export default function Hardwares() {
	const [hardwares, setHardwares] = useState([]),
		[isHardwaresLoaded, setIsHardwaresLoaded] = useState(false),
		[isFetchingHardwares, setIsFetchingHardwares] = useState(false),
		{ startLoading, stopLoading } = useContext(LoadingContext),
		{ httpGet, httpPut, httpPost, httpDelete } = useFetchWithMsal(),
		{ enqueueSnackbar } = useSnackbar(),
		{ canAccess } = useContext(AuthContext),
		isAdmin = canAccess([ROLESDATA.Administrator.RoleName]),
		handleCreateRowSave = ({ values, table }) => {
			const hardwareInfo = {
				description: values.description,
			};
			handleSave(hardwareInfo, table, true);
		},
		handleEditingRowSave = ({ values, table, row }) => {
			const hardwareInfo = {
				description: values.description,
			};
			handleSave(hardwareInfo, table, false, row.original.id);
		},
		handleSave = (hardwareInfo, table, isCreating, id = null) => {
			startLoading();

			if (isCreating) {
				httpPost({
					urlSubPath: 'hardwares',
					body: hardwareInfo,
				})
					.then(() => {
						table.setCreatingRow(null);
						setIsHardwaresLoaded(false);
						enqueueSnackbar('Hardware created successfully', {
							variant: 'success',
						});
					})
					.catch((error) => {
						logger.error(error);
						enqueueSnackbar(`Unable to create hardware. ${error}`, {
							variant: 'error',
							preventDuplicate: true,
						});
					})
					.finally(() => {
						stopLoading();
					});
			} else {
				httpPut({
					urlSubPath: `hardwares/${id}`,
					body: hardwareInfo,
				})
					.then(() => {
						table.setEditingRow(null);
						setIsHardwaresLoaded(false);
						enqueueSnackbar('Hardware updated successfully', {
							variant: 'success',
						});
					})
					.catch((error) => {
						logger.error(error);
						enqueueSnackbar(`Unable to update hardware. ${error}`, {
							variant: 'error',
							preventDuplicate: true,
						});
					})
					.finally(() => {
						stopLoading();
					});
			}
		},
		handleClickDeleteRow = (id) => {
			if (window.confirm('Are you sure you want to delete this hardware?')) {
				startLoading();
				httpDelete({
					urlSubPath: `hardwares/${id}`,
				})
					.then(() => {
						setIsHardwaresLoaded(false);
						enqueueSnackbar('Hardware deleted successfully', {
							variant: 'success',
						});
					})
					.catch((error) => {
						enqueueSnackbar(`Unable to delete hardware. ${error}`, {
							variant: 'error',
							preventDuplicate: true,
						});
					})
					.finally(() => {
						stopLoading();
					});
			}
		},
		loadHardwares = useCallback(() => {
			if (isFetchingHardwares) return;
			setIsFetchingHardwares(true);
			startLoading();
			httpGet({
				urlSubPath: 'hardwares',
			})
				.then((response) => {
					setHardwares(response);
					setIsHardwaresLoaded(true);
				})
				.catch((error) => {
					logger.error(error);
					enqueueSnackbar(`Unable to load hardwares. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					setIsFetchingHardwares(false);
					stopLoading();
				});
		}, [enqueueSnackbar, httpGet, startLoading, stopLoading, isFetchingHardwares]),
		columns = useMemo(
			() => [
				{
					accessorKey: 'id',
					header: 'ID',
					enableEditing: false,
					size: 100,
					enableHiding: true,
					defaultHidden: true,
				},
				{
					accessorKey: 'description',
					header: 'Description',
				},
			],
			[]
		),
		table = useMaterialReactTable({
			columns,
			data: hardwares,
			editDisplayMode: 'row',
			createDisplayMode: 'row',
			enableEditing: isAdmin,
			enableColumnOrdering: false,
			enableGlobalFilter: true,
			enableFullScreenToggle: false,
			enableRowActions: true,
			enableStickyHeader: true,
			enableStickyFooter: true,
			getRowId: (row) => row.id,
			initialState: {
				pagination: { pageSize: 50 },
				density: 'compact',
				columnVisibility: { id: false },
			},
			muiTableContainerProps: {
				sx: {
					minHeight: '500px',
					maxHeight: 'calc(100vh - 225px)',
				},
			},
			onCreatingRowSave: handleCreateRowSave,
			onEditingRowSave: handleEditingRowSave,
			renderRowActions: ({ row, table }) => (
				<Box sx={{ display: 'flex', gap: '0.7rem' }}>
					{isAdmin && (
						<>
							<Tooltip title="Edit">
								<IconButton onClick={() => table.setEditingRow(row)}>
									<EditIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Delete">
								<IconButton color="error" onClick={() => handleClickDeleteRow(row.original.id)}>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</>
					)}
				</Box>
			),
			renderTopToolbarCustomActions: ({ table }) => (
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					{isAdmin && (
						<Button
							variant="contained"
							onClick={() => {
								table.setCreatingRow(true);
							}}
						>
							Register New Hardware
						</Button>
					)}
					<IconButton onClick={loadHardwares}>
						<RefreshIcon />
					</IconButton>
				</Box>
			),
		});

	useEffect(() => {
		if (!isHardwaresLoaded && !isFetchingHardwares) {
			loadHardwares();
		}
	}, [isHardwaresLoaded, isFetchingHardwares, loadHardwares]);

	return (
		<AppContainer>
			<MaterialReactTable table={table} />
		</AppContainer>
	);
}
