import { useCallback } from 'react';

import { useAuth } from './useAuth';
import { getHeaders } from '../utils/httpRequestUtils';
import httpResponseWrapper from '../utils/httpResponseWrapper';

const useFetchWithMsal = () => {
	const { getAccessToken } = useAuth(),
		apiBasePath = process.env.REACT_APP_API_BASE_PATH,
		buildQueryString = (queryParams) => {
			return Object.keys(queryParams)
				.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
				.join('&');
		},
		httpGet = useCallback(
			async (params) => {
				const { extraParams, queryParams, requestId, urlSubPath } = params,
					accessToken = await getAccessToken(),
					headers = getHeaders({
						accessToken,
						acceptHeader: extraParams && extraParams.acceptHeader,
						requestId,
					}),
					queryString = queryParams && queryParams != {} ? `?${buildQueryString(queryParams)}` : '',
					promise = fetch(`${apiBasePath}/${urlSubPath}${queryString}`, {
						method: 'GET',
						headers,
					}),
					results = await handleResponse(promise, extraParams);
				return results;
			},
			[apiBasePath, getAccessToken]
		),
		httpPut = useCallback(
			async (params) => {
				const { urlSubPath, requestId } = params,
					accessToken = await getAccessToken(),
					body = params.body,
					headers = getHeaders({ accessToken, requestId }),
					options = {
						method: 'PUT',
						headers,
					};

				if (body) {
					options.body = JSON.stringify(body);
				}
				const results = await fetch(`${apiBasePath}/${urlSubPath}`, options)
					.then(httpResponseWrapper)
					.then((res) => res.json());
				return results;
			},
			[apiBasePath, getAccessToken]
		),
		httpPost = useCallback(
			async (params) => {
				const { urlSubPath, requestId } = params,
					accessToken = await getAccessToken(),
					body = params.body,
					headers = getHeaders({ accessToken, acceptHeader: params.acceptHeader, requestId }),
					options = {
						method: 'POST',
						headers,
					};

				if (body) {
					options.body = JSON.stringify(body);
				}
				const results = await fetch(`${apiBasePath}/${urlSubPath}`, options)
					.then(httpResponseWrapper)
					.then((res) => res.json());
				return results;
			},
			[apiBasePath, getAccessToken]
		),
		httpDelete = useCallback(
			async (params) => {
				const { urlSubPath, requestId } = params,
					accessToken = await getAccessToken(),
					body = params.body,
					headers = getHeaders({ accessToken, acceptHeader: '*/*', noContentType: true, requestId }),
					options = {
						method: 'DELETE',
						headers,
					};

				if (body) {
					options.body = JSON.stringify(body);
				}
				const results = await fetch(`${apiBasePath}/${urlSubPath}`, options)
					.then(httpResponseWrapper)
					.then((res) => res.json());
				return results;
			},
			[apiBasePath, getAccessToken]
		),
		handleResponse = async (response, extraParams) => {
			const isDownload = extraParams && extraParams.isDownload;
			if (response instanceof Response) {
				return response;
			}
			return await response.then(httpResponseWrapper).then((res) => {
				if (isDownload) {
					return res.blob();
				}
				return res.json();
			});
		};

	return {
		httpGet,
		httpPut,
		httpPost,
		httpDelete,
	};
};

export default useFetchWithMsal;
