import { LogLevel } from '@azure/msal-browser';

import { logger } from './utils/loggingUtils';

export const tenantName = process.env.REACT_APP_TENANT_NAME,
	clientId = process.env.REACT_APP_APP_CLIENT_ID,
	signUpUserFlow = process.env.REACT_APP_SIGN_UP_USER_FLOW,
	signupSignInUserFlow = process.env.REACT_APP_SIGN_UP_SIGN_IN_USER_FLOW,
	resetPasswordUserFlow = process.env.REACT_APP_RESET_PASSWORD_USER_FLOW,
	editProfileUserFlow = process.env.REACT_APP_EDIT_PROFILE_USER_FLOW,
	b2cPolicies = {
		names: {
			signUp: signUpUserFlow,
			signUpSignIn: signupSignInUserFlow,
			forgotPassword: resetPasswordUserFlow,
			editProfile: editProfileUserFlow,
		},
		authorities: {
			signUp: {
				authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${signUpUserFlow}`,
			},
			signUpSignIn: {
				authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${signupSignInUserFlow}`,
			},
			forgotPassword: {
				authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${resetPasswordUserFlow}`,
			},
			editProfile: {
				authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${editProfileUserFlow}`,
			},
		},
		authorityDomain: `${tenantName}.b2clogin.com`,
	};

export const msalConfig = {
	auth: {
		clientId,
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: '/',
		postLogoutRedirectUri: '/',
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: true,
	},
	system: {
		loggerOptions: {
			logLevel: LogLevel.Warning,
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						logger.error(message);
						return;
					case LogLevel.Info:
						logger.info(message);
						return;
					case LogLevel.Verbose:
						logger.debug(message);
						return;
					case LogLevel.Warning:
						logger.warn(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const protectedResources = {
	apis: {
		endpoint: `${process.env.REACT_APP_API_BASE_PATH}`,
		scopes: {
			read: [`https://${tenantName}.onmicrosoft.com/${process.env.REACT_APP_API_READ_PERMISSION_PATH}`],
			write: [`https://${tenantName}.onmicrosoft.com/${process.env.REACT_APP_API_WRITE_PERMISSION_PATH}`],
		},
	},
};

export const loginRequest = {
	scopes: [...protectedResources.apis.scopes.read, ...protectedResources.apis.scopes.write],
};
