import { get } from '../utils/httpRequestUtils';

/**
 * @typedef {Object} FeatureFlag
 * @property {number} id
 * @property {string} name
 * @property {boolean} enabled
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {FeatureFlagAssignment[] | null} featureFlagAssignments
 */

/**
 * @typedef {Object} FeatureFlagAssignment
 * @property {number} id
 * @property {number} featureFlagId
 * @property {number} entityId
 * @property {string} entityType
 * @property {string} createdAt
 * @property {string} updatedAt
 */

// Create a more robust cache with debugging
const featureFlagCache = (() => {
	let cachedFlags = null,
		lastFetchTime = null;
	const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

	return {
		get: () => ({
			flags: cachedFlags,
			timestamp: lastFetchTime,
		}),
		set: (flags) => {
			cachedFlags = flags;
			lastFetchTime = Date.now();
		},
		isValid: () => {
			const valid = cachedFlags && lastFetchTime && Date.now() - lastFetchTime < CACHE_TTL;
			return valid;
		},
		clear: () => {
			cachedFlags = null;
			lastFetchTime = null;
		},
	};
})();

// Create a request deduplication mechanism
let pendingRequest = null;

/**
 * Fetches all feature flags with their assignments from the API
 * @param {string} accessToken
 * @returns {Promise<FeatureFlag[]>}
 */
const fetchFeatureFlags = async (accessToken) => {
	const urlSubPath = 'feature-flags',
		params = { urlSubPath, accessToken };
	return await get(params);
};

/**
 * Gets all feature flags with their assignments, either from cache or by fetching from the API
 * @param {string} accessToken
 * @returns {Promise<FeatureFlag[]>}
 */
export const getFeatureFlags = async (accessToken) => {
	// First check cache
	if (featureFlagCache.isValid()) {
		return featureFlagCache.get().flags;
	}

	try {
		// If there's already a request in flight, wait for it
		if (pendingRequest) {
			return await pendingRequest;
		}

		// Start new request
		pendingRequest = fetchFeatureFlags(accessToken);
		const flags = await pendingRequest;

		featureFlagCache.set(flags);
		return flags;
	} finally {
		pendingRequest = null;
	}
};

/**
 * Checks if a specific feature flag is enabled for the current user
 * @param {string} flagName
 * @param {string} accessToken
 * @param {string|number} userId
 * @returns {Promise<boolean>}
 */
export const isFeatureEnabled = async (flagName, accessToken, userId) => {
	// Convert userId to a number
	const numericUserId = Number(userId);

	// If conversion fails (NaN), return false
	if (isNaN(numericUserId)) {
		return false;
	}

	const flags = await getFeatureFlags(accessToken),
		flag = flags.find((f) => f.name === flagName);

	if (!flag) return false;

	if (flag.featureFlagAssignments) {
		const userAssignment = flag.featureFlagAssignments.find((a) => a.userId === numericUserId);
		if (userAssignment) {
			return userAssignment.enabled;
		}
	}

	return flag.enabled;
};
