import React, { useContext, useEffect, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Stack, Collapse, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import SidebarData from './SidebarData';
import { AuthContext } from '../../context';
import { useFeatureFlags } from '../../providers/FeatureFlagProvider';

import './style.css';

export function Sidebar(props) {
	const { canAccess } = useContext(AuthContext),
		{ checkFeatureFlag } = useFeatureFlags(),
		[featureFlagStates, setFeatureFlagStates] = useState({}),
		[projectsExpanded, setProjectsExpanded] = useState(true),
		navigate = useNavigate();
	// Check all feature flags on component mount
	useEffect(() => {
		const checkFlags = async () => {
			const flagPromises = SidebarData.reduce((promises, item) => {
					if (item.featureFlag) {
						promises[item.featureFlag] = checkFeatureFlag(item.featureFlag);
					}
					if (item.children) {
						item.children.forEach((child) => {
							if (child.featureFlag) {
								promises[child.featureFlag] = checkFeatureFlag(child.featureFlag);
							}
						});
					}
					return promises;
				}, {}),
				results = {};
			for (const [flag, promise] of Object.entries(flagPromises)) {
				results[flag] = await promise;
			}
			setFeatureFlagStates(results);
		};
		checkFlags();
	}, [checkFeatureFlag]);

	const shouldShowItem = (item) => {
			const hasFeatureAccess = item.featureFlag ? featureFlagStates[item.featureFlag] : true,
				hasRoleAccess = item.allowedRoles ? canAccess(item.allowedRoles) : true;
			return hasFeatureAccess && hasRoleAccess;
		},
		getVisibleChildren = (item) => {
			if (!item.children) return [];
			return item.children.filter((child) => shouldShowItem(child));
		},
		renderSidebarItem = (item) => {
			if (!shouldShowItem(item)) return null;

			const visibleChildren = getVisibleChildren(item),
				showExpandIcon = item.children && visibleChildren.length > 0;

			return (
				<React.Fragment key={item.id}>
					<li className={item.cName}>
						{item.allowedRoles ? (
							<Link
								to={item.path}
								disabled={!canAccess(item.allowedRoles)}
								onClick={(e) => {
									if (!canAccess(item.allowedRoles)) return;
									if (showExpandIcon) {
										e.preventDefault();
										navigate(item.path);
									}
								}}
							>
								{item.icon}
								<span>{item.title}</span>
								{showExpandIcon && (
									<IconButton
										className="expand-button"
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setProjectsExpanded(!projectsExpanded);
										}}
									>
										{projectsExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
									</IconButton>
								)}
							</Link>
						) : (
							<Link to={item.path}>
								{item.icon}
								<span>{item.title}</span>
							</Link>
						)}
					</li>
					{showExpandIcon && (
						<Collapse in={projectsExpanded}>
							{visibleChildren.map((child) => renderSidebarItem(child))}
						</Collapse>
					)}
				</React.Fragment>
			);
		};

	return (
		<nav className={props.appToSidebar ? 'sidebar-menu active' : 'sidebar-menu'}>
			<Stack direction={'row'} width={'100%'}>
				<ul
					className="sidebar-menu-items"
					onClick={() => props.sidebarToApp(props.appToSidebar)}
					role="presentation"
				>
					<div className="sidebar-menu-container">
						{SidebarData.filter((item) => shouldShowItem(item)).map((item) => renderSidebarItem(item))}
					</div>
				</ul>
				<Box className="sidebar-overlay"></Box>
			</Stack>
		</nav>
	);
}

export default Sidebar;
