import { useContext } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../context/auth/AuthContext';

export const useAuth = () => {
	const auth = useContext(AuthContext),
		navigate = useNavigate(),
		{ enqueueSnackbar } = useSnackbar(),
		getAccessToken = async () => {
			try {
				return await auth.getAccessToken();
			} catch (error) {
				console.error('Error getting access token:', error);
				enqueueSnackbar('Unable to authenticate. Please try again.', { variant: 'error' });
				navigate('/401');
				return null;
			}
		};

	return {
		...auth,
		getAccessToken,
	};
};
