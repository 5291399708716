import { React, StrictMode } from 'react';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';

import App from './App';
import { msalConfig } from './authConfig';
import LoadingOverlay from './components/loadingOverlay';
import { AuthProvider } from './context/auth';
import { LoadingProvider } from './context/loading';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 */
const msalInstance = new PublicClientApplication(msalConfig);

async function initializeMsal() {
	await msalInstance.initialize();

	// Default to using the first account if no account is active on page load
	if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
		// Account selection logic is app dependent. Adjust as needed for different use cases.
		msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
	}

	msalInstance.addEventCallback((event) => {
		if (
			(event.eventType === EventType.LOGIN_SUCCESS ||
				event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
				event.eventType === EventType.SSO_SILENT_SUCCESS) &&
			event.payload.account
		) {
			msalInstance.setActiveAccount(event.payload.account);
		}
	});
}

initializeMsal().then(() => {
	const root = ReactDOM.createRoot(document.getElementById('root')),
		app = (
			<MsalProvider instance={msalInstance}>
				<SnackbarProvider maxSnack={3}>
					<LoadingProvider>
						<LoadingOverlay />
						<AuthProvider>
							<App instance={msalInstance} />
						</AuthProvider>
					</LoadingProvider>
				</SnackbarProvider>
			</MsalProvider>
		);

	root.render(process.env.NODE_ENV != 'prod' ? <StrictMode>{app}</StrictMode> : app);
});

reportWebVitals();
