const httpResponseWrapper = async (response) => {
	if (response.ok && response.status >= 200 && response.status < 300) {
		return response;
	}
	let errorMessage = response.statusText;
	try {
		errorMessage = await response.text((message) => {
			return message;
		});
	} catch (error) {
		errorMessage = error.message;
	}
	throw new Error(errorMessage);
};
export default httpResponseWrapper;
