import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import PropTypes from 'prop-types';

const TreeView = ({ dataList, dataObject, onItemSelect, title }) => {
	const renderTree = (nodes) => (
		<TreeItem
			key={nodes.id}
			nodeId={nodes.id.toString()}
			label={nodes.name}
			onClick={(event) => {
				// Prevent click event from triggering when expanding/collapsing
				if (event.target.classList.contains('MuiTreeItem-label')) {
					onItemSelect(nodes.id);
				}
			}}
		>
			{Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
		</TreeItem>
	);

	return (
		<>
			{title && <h3>{title}</h3>}
			<SimpleTreeView
				aria-label="location tree"
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
			>
				{dataList ? dataList.map((node) => renderTree(node)) : renderTree(dataObject)}
			</SimpleTreeView>
		</>
	);
};

TreeView.propTypes = {
	dataList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			name: PropTypes.string.isRequired,
			children: PropTypes.array,
		})
	),
	dataObject: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		name: PropTypes.string.isRequired,
		children: PropTypes.array,
	}),
	onItemSelect: PropTypes.func.isRequired,
	title: PropTypes.string,
};

TreeView.propTypes = {
	...TreeView.propTypes,
	dataList: (props, propName, componentName) => {
		if (!props.dataList && !props.dataObject) {
			return new Error(`One of 'dataList' or 'dataObject' is required in '${componentName}'.`);
		}
		if (props.dataList && props.dataObject) {
			return new Error(`Only one of 'dataList' or 'dataObject' should be provided in '${componentName}'.`);
		}
	},
	dataObject: (props, propName, componentName) => {
		if (!props.dataList && !props.dataObject) {
			return new Error(`One of 'dataList' or 'dataObject' is required in '${componentName}'.`);
		}
		if (props.dataList && props.dataObject) {
			return new Error(`Only one of 'dataList' or 'dataObject' should be provided in '${componentName}'.`);
		}
	},
};

export default TreeView;
