import React, { useContext, useEffect } from 'react';

import { EventType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

import AppRoutes from './AppRouters';
import { b2cPolicies, protectedResources } from './authConfig';
import ResponsiveAppBar from './components/appBar';
import { AuthContext } from './context';
import { AppProviders } from './providers/AppProviders';
import { initializeAppInsights } from './services/appInsightsService';
import { getUserInfo } from './services/userService';
import { compareIssuingPolicy } from './utils/claimUtils';
import { logger } from './utils/loggingUtils';
import './styles/App.css';

function App() {
	const { instance } = useMsal(),
		{ isLoggedIn, isAuthorizingUser, login, logout, toggleIsAuthorizingUser } = useContext(AuthContext);

	useEffect(() => {
		const handleLogout = () => {
				logout();
				// Remove the access token from localStorage cache
				localStorage.removeItem('accessToken');
				// set a logout flag in localStorage to trigger a page reload in other signed in tabs
				localStorage.setItem('logout', Date.now().toString());
			},
			callbackId = instance.addEventCallback((event) => {
				if (event.eventType === EventType.HANDLE_REDIRECT_END) {
					const activeAccount = instance.getActiveAccount();
					if (!activeAccount) {
						let signUpSignInFlowRequest = {
							authority: b2cPolicies.authorities.signUpSignIn.authority,
							scopes: [...protectedResources.apis.scopes.read, ...protectedResources.apis.scopes.write],
						};
						instance.loginRedirect(signUpSignInFlowRequest);
					}
				} else if (
					(event.eventType === EventType.LOGIN_SUCCESS ||
						event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
					event.payload.account
				) {
					const isProfileEditUserFlow = compareIssuingPolicy(
							event.payload.idTokenClaims,
							b2cPolicies.names.editProfile
						),
						isForgotPasswordUserFlow = compareIssuingPolicy(
							event.payload.idTokenClaims,
							b2cPolicies.names.forgotPassword
						);

					/**
					 * For the purpose of setting an active account for UI update, we want to consider only the auth
					 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
					 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
					 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
					 */
					if (isProfileEditUserFlow) {
						// retrieve the account from initial sing-in to the app
						const originalSignInAccount = instance
							.getAllAccounts()
							.find(
								(account) =>
									account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
									account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
									compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
							);

						let signUpSignInFlowRequest = {
							authority: b2cPolicies.authorities.signUpSignIn.authority,
							account: originalSignInAccount,
						};

						// silently login again with the signUpSignIn policy
						instance.ssoSilent(signUpSignInFlowRequest);
					} else if (isForgotPasswordUserFlow) {
						/**
						 * Below we are checking if the user is returning from the reset password flow.
						 * If so, we will ask the user to reauthenticate with their new password.
						 * If you do not want this behavior and prefer your users to stay signed in instead,
						 * you can replace the code below with the same pattern used for handling the return from
						 * profile edit flow
						 */
						let signUpSignInFlowRequest = {
							authority: b2cPolicies.authorities.signUpSignIn.authority,
							scopes: [...protectedResources.apis.scopes.read, ...protectedResources.apis.scopes.write],
						};
						instance.loginRedirect(signUpSignInFlowRequest);
					} else {
						let { accessToken, account } = event.payload;
						// on first login, access token will be available from event post-login - cache in localStorage
						if (accessToken) {
							localStorage.setItem('accessToken', accessToken);
						} // on subsequent page hits, the access token is available via cached localStorage
						else {
							accessToken = localStorage.getItem('accessToken');
						}
						if (accessToken && account && !isLoggedIn() && !isAuthorizingUser()) {
							toggleIsAuthorizingUser(true);
							getUserInfo(accessToken, account)
								.then((response) => {
									login(response);
									toggleIsAuthorizingUser(false);
								})
								.catch((error) => {
									toggleIsAuthorizingUser(false);
									logger.error(error);
									instance.logout();
								});
						}
					}
				}
				if (event.eventType === EventType.LOGOUT_SUCCESS) {
					handleLogout();
				} else if (event.eventType === EventType.LOGIN_FAILURE) {
					// Check for forgot password error
					if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
						const resetPasswordRequest = {
							authority: b2cPolicies.authorities.forgotPassword.authority,
							scopes: [],
						};
						instance.loginRedirect(resetPasswordRequest);
					}
				}
			});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [instance, login, logout, toggleIsAuthorizingUser, isAuthorizingUser, isLoggedIn]);

	useEffect(() => {
		// Listen for logout events from other windows
		const handleLocalStorageChange = (event) => {
			// If the logout key is found in localStorage, refresh page to log user out without MSAL concurrency issues
			if (event.key === 'logout') {
				window.location.reload();
			}
		};

		window.addEventListener('storage', handleLocalStorageChange);

		return () => {
			window.removeEventListener('storage', handleLocalStorageChange);
		};
	}, [instance]);

	useEffect(() => {
		initializeAppInsights();
	}, []);

	return (
		<AppProviders>
			<MsalAuthenticationTemplate>
				<AuthenticatedTemplate>
					<AppRoutes />
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<ResponsiveAppBar />
					<p>You are not signed in! Please sign in.</p>
					<AppRoutes />
				</UnauthenticatedTemplate>
			</MsalAuthenticationTemplate>
		</AppProviders>
	);
}

export default App;
