import React from 'react';
import { useContext } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { LoadingContext } from '../../context/loading';

export default function LoadingOverlay() {
	const { loading } = useContext(LoadingContext);

	return (
		<Backdrop
			open={loading}
			sx={{
				zIndex: (theme) => theme.zIndex.modal + 1,
				color: '#fff',
			}}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}
