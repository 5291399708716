import { types } from './types';

export const authReducer = (state = {}, action) => {
	switch (action.type) {
		case types.login:
			return {
				...state,
				isLoggedIn: true,
				logged: true,
				userId: action.payload.id,
				...action.payload,
			};

		case types.logout:
			return {
				isActive: false,
				isLoggedIn: false,
				logged: false,
				roles: [],
			};

		default:
			return state;
	}
};
