import React from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const BoxWrapper = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		width: '90vw',
	},
}));

export const Error404 = () => {
	const navigate = useNavigate();

	return (
		<Box className="content-center editable-always">
			<Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
				<BoxWrapper>
					<Typography variant="h1" sx={{ mb: 2.5 }}>
						404
					</Typography>
					<Typography variant="h5" sx={{ mb: 2.5, letterSpacing: '0.18px', fontSize: '1.5rem !important' }}>
						Page Not Found ⚠️
					</Typography>
					<Typography variant="body2">We couldn&prime;t find the page you are looking for.</Typography>
				</BoxWrapper>

				<Button variant="contained" sx={{ px: 5.5, mt: 2.5 }} onClick={() => navigate('/')}>
					Back to Home
				</Button>
			</Box>
		</Box>
	);
};
export default Error404;
