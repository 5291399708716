import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useSnackbar } from 'notistack';
// import { v4 as uuidv4 } from 'uuid';

import ActivityFormDialog from './activityFormDialog';
import { ROLESDATA } from '../../components/aclContainer/roles';
import AppContainer from '../../components/appContainer';
import { LoadingContext } from '../../context';
import { AuthContext } from '../../context';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { useScreenSize, SIZE } from '../../hooks/useScreenSize';
import { logger } from '../../utils/loggingUtils';

export default function ActivityPage() {
	const [activities, setActivities] = useState([]),
		// { componentRequestUUID } = uuidv4(),
		{ startLoading, stopLoading } = React.useContext(LoadingContext),
		[isActivitiesLoaded, setIsActivitiesLoaded] = useState(false),
		[isFetchingActivities, setIsFetchingActivities] = useState(false),
		{ httpPut, httpGet, httpDelete } = useFetchWithMsal(),
		{ size } = useScreenSize(),
		{ enqueueSnackbar } = useSnackbar(),
		{ canAccess } = useContext(AuthContext),
		isAdmin = canAccess([ROLESDATA.Administrator.RoleName]),
		handleCreateRowSave = ({ values, table }) => {
			handleSave(null, values, table, true);
		},
		handleEditingRowSave = ({ values, table, row }) => {
			handleSave(row.id, values, table, false);
		},
		handleSave = (id, values, table, isCreating) => {
			const {
					description,
					defaultIsBillable,
					isJobRelated,
					isHardwareRelated,
					isObservationRelated,
					isTimecardRelated,
				} = values,
				activity = {
					id,
					description,
					defaultIsBillable,
					isJobRelated,
					isHardwareRelated,
					isObservationRelated,
					isTimecardRelated,
				};
			startLoading();
			httpPut({
				urlSubPath: 'activity',
				body: activity,
				// requestId: `${activity.id}-${componentRequestUUID}`,
			})
				.then(() => {
					isCreating ? table.setCreatingRow(null) : table.setEditingRow(null);
					setIsActivitiesLoaded(false);
				})
				.catch((error) => {
					enqueueSnackbar(`Unable to save activity. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					stopLoading();
				});
		},
		handleClickEditRow = (table, row) => {
			table.setEditingRow(row);
		},
		openDeleteConfirmModal = (table, row) => {
			if (window.confirm('Are you sure you want to delete this activity?')) {
				handleClickDeleteRow(row.original.id);
			}
		},
		handleClickDeleteRow = (id) => {
			startLoading();
			httpDelete({
				urlSubPath: `activity/${id}`,
				// requestId: `${activity.id}-${componentRequestUUID}`,
			})
				.then(() => {
					setIsActivitiesLoaded(false);
				})
				.catch((error) => {
					enqueueSnackbar(`Unable to delete activity. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					stopLoading();
				});
		},
		loadActivities = useCallback(() => {
			if (isFetchingActivities) return;
			setIsFetchingActivities(true);
			startLoading();
			httpGet({
				urlSubPath: 'activities',
			})
				.then((response) => {
					setActivities(response);
					setIsActivitiesLoaded(true);
				})
				.catch((error) => {
					logger.error(error);
					enqueueSnackbar(`Unable to load activities. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					setIsFetchingActivities(false);
					stopLoading();
				});
		}, [enqueueSnackbar, httpGet, startLoading, stopLoading, isFetchingActivities]),
		columns = useMemo(
			() => [
				{
					accessorKey: 'id',
					header: 'ID',
					size: size === SIZE.small ? 100 : 150,
				},
				{
					accessorKey: 'description',
					header: 'Description',
					size: size === SIZE.small ? 100 : 150,
				},
				{
					accessorKey: 'defaultIsBillable',
					header: 'Defaults to Billable',
					size: size === SIZE.small ? 80 : 100,
					Cell: ({ cell }) => {
						return cell.getValue() === true ? <CheckIcon /> : <CloseIcon />;
					},
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
				},
				{
					accessorKey: 'isTimecardRelated',
					header: 'Timecard Related',
					size: size === SIZE.small ? 80 : 100,
					Cell: ({ cell }) => {
						return cell.getValue() === true ? <CheckIcon /> : <CloseIcon />;
					},
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
				},
				{
					accessorKey: 'isObservationRelated',
					header: 'Observation Related',
					size: size === SIZE.small ? 80 : 100,
					Cell: ({ cell }) => {
						return cell.getValue() === true ? <CheckIcon /> : <CloseIcon />;
					},
					muiTableHeadCellProps: {
						align: 'center',
					},
					muiTableBodyCellProps: {
						align: 'center',
					},
				},
			],
			[size]
		),
		table = useMaterialReactTable({
			columns,
			data: activities,
			createDisplayMode: 'modal',
			editDisplayMode: 'modal',
			enableEditing: isAdmin,
			enableFullScreenToggle: false,
			getRowId: (row) => row.id,
			muiTableContainerProps: {
				sx: {
					minHeight: '500px',
					maxHeight: 'calc(100vh - 225px)',
				},
			},
			initialState: {
				pagination: {
					pageSize: 50,
				},
				sorting: [
					{
						id: 'id',
						desc: false,
					},
				],
			},
			onCreatingRowSave: (params) => handleCreateRowSave(params),
			onEditingRowSave: handleEditingRowSave,
			renderRowActions: ({ row, table }) =>
				isAdmin ? (
					<Box sx={{ display: 'flex', gap: '0.7rem' }}>
						<Tooltip title="Edit">
							<IconButton onClick={() => handleClickEditRow(table, row)}>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete">
							<IconButton color="error" onClick={() => openDeleteConfirmModal(table, row)}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Box>
				) : null,
			renderTopToolbarCustomActions: ({ table }) =>
				isAdmin ? (
					<Button
						variant="contained"
						onClick={() => {
							table.setCreatingRow(true);
						}}
					>
						Create Activity
					</Button>
				) : null,
			renderCreateRowDialogContent: ({ table, row }) => <ActivityFormDialog table={table} row={row} />,
			renderEditRowDialogContent: ({ table, row }) => <ActivityFormDialog table={table} row={row} />,
			muiCreateRowDialogProps: {
				fullScreen: true,
			},
			muiEditRowDialogProps: {
				fullScreen: true,
			},
			enableStickyHeader: true,
			enableStickyFooter: true,
		});

	useEffect(() => {
		if (!isActivitiesLoaded && !isFetchingActivities) {
			loadActivities();
		}
	}, [isActivitiesLoaded, isFetchingActivities, loadActivities]);

	return (
		<AppContainer>
			<MaterialReactTable table={table} />
		</AppContainer>
	);
}
