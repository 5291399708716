import React, { useState } from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import ResponsiveAppBar from '../components/appBar';
import Sidebar from '../components/sidebar';

const MainLayoutWrapper = styled('div')({
		height: '100%',
		display: 'flex',
	}),
	MainContentWrapper = styled(Box)({
		flexGrow: 1,
		minWidth: 0,
		display: 'flex',
		flexDirection: 'column',
	});

export const MainLayout = () => {
	const [sidebar, setSidebar] = useState(false),
		navToApp = (sidebarOpen) => {
			setSidebar(!sidebarOpen);
		},
		sidebarToApp = (sidebarOpen) => {
			setSidebar(!sidebarOpen);
		};

	return (
		<MainLayoutWrapper>
			<ResponsiveAppBar navToApp={navToApp} appToNav={sidebar} />
			<MainContentWrapper className="layout-content-wrapper">
				<Outlet />
			</MainContentWrapper>
			<Sidebar appToSidebar={sidebar} sidebarToApp={sidebarToApp} />
		</MainLayoutWrapper>
	);
};
