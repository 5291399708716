import React, { useState, useEffect, useCallback } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
	Modal,
	Box,
	Typography,
	IconButton,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	useMediaQuery,
	useTheme,
	Button,
} from '@mui/material';

import useFetchWithMsal from '../../../hooks/useFetchWithMsal';
import './style.css';

const AttachmentModal = ({ open, handleClose, attachments }) => {
	const [currentIndex, setCurrentIndex] = useState(0),
		[sasToken, setSasToken] = useState(null),
		{ httpGet } = useFetchWithMsal(),
		theme = useTheme(),
		isSmallScreen = useMediaQuery(theme.breakpoints.down('md')),
		FILE_LIST_WIDTH = 300; // Static width for the file list

	useEffect(() => {
		setCurrentIndex(0);
		if (open && attachments && attachments.length > 0) {
			getSasToken();
		}
	}, [open, attachments]);

	const getSasToken = async () => {
			try {
				const { sasToken } = await httpGet({
					urlSubPath: 'sas-token',
					queryParams: { containerName: process.env.REACT_APP_ABS_ATTACHMENTS_CONTAINER_NAME },
				});
				setSasToken(sasToken);
			} catch (error) {
				console.error('Error fetching SAS token:', error);
			}
		},
		getAuthenticatedUri = (uri) => {
			return sasToken ? `${uri}?${sasToken}` : uri;
		},
		nextAttachment = useCallback(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % attachments.length);
		}, [attachments]),
		prevAttachment = useCallback(() => {
			setCurrentIndex((prevIndex) => (prevIndex - 1 + attachments.length) % attachments.length);
		}, [attachments]),
		handleKeyDown = useCallback(
			(event) => {
				if (event.key === 'ArrowLeft') {
					prevAttachment();
				} else if (event.key === 'ArrowRight') {
					nextAttachment();
				}
			},
			[prevAttachment, nextAttachment]
		);

	useEffect(() => {
		if (open) {
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [open, handleKeyDown]);

	const handleDownload = async (attachment) => {
			const authenticatedUri = getAuthenticatedUri(attachment.uri);
			try {
				const response = await fetch(authenticatedUri),
					blob = await response.blob(),
					url = window.URL.createObjectURL(blob),
					link = document.createElement('a');
				link.href = url;
				link.download = attachment.filename;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error('Error downloading file:', error);
			}
		},
		renderContent = (attachment) => {
			if (!attachment) return null;

			const fileType = attachment.filename.split('.').pop().toLowerCase(),
				authenticatedUri = getAuthenticatedUri(attachment.uri);

			switch (fileType) {
				case 'jpg':
				case 'jpeg':
				case 'png':
				case 'gif':
					return (
						<div className="attachment-content">
							<Typography variant="h6" className="attachment-filename">
								{attachment.filename}
							</Typography>
							<div className="attachment-image-container">
								<img src={authenticatedUri} alt={attachment.filename} className="attachment-image" />
							</div>
							<div className="attachment-download-container">
								<Button
									onClick={() => handleDownload(attachment)}
									startIcon={<DownloadIcon />}
									variant="contained"
									color="primary"
									className="attachment-download-button"
								>
									Download
								</Button>
							</div>
						</div>
					);
				case 'pdf':
					return (
						<div className="attachment-content">
							<Typography variant="h6" className="attachment-filename">
								{attachment.filename}
							</Typography>
							<div className="attachment-pdf-container">
								<iframe
									src={`${authenticatedUri}#toolbar=0`}
									title={attachment.filename}
									className="attachment-pdf"
								/>
							</div>
							<div className="attachment-download-container">
								<Button
									onClick={() => handleDownload(attachment)}
									startIcon={<DownloadIcon />}
									variant="contained"
									color="primary"
									className="attachment-download-button"
								>
									Download
								</Button>
							</div>
						</div>
					);
				default:
					return (
						<div className="attachment-content">
							<Typography variant="h6" className="attachment-filename">
								{attachment.filename}
							</Typography>
							<Typography variant="body1" className="attachment-default-text">
								{attachment.filename}
							</Typography>
							<div className="attachment-download-container">
								<Button
									onClick={() => handleDownload(attachment)}
									startIcon={<DownloadIcon />}
									variant="contained"
									color="primary"
									className="attachment-download-button"
								>
									Download
								</Button>
							</div>
						</div>
					);
			}
		};

	if (!attachments || attachments.length === 0) {
		return (
			<Modal open={open} onClose={handleClose}>
				<Box className="no-attachments-box">
					<Typography>No attachments available.</Typography>
				</Box>
			</Modal>
		);
	}

	const currentAttachment = attachments[currentIndex],
		navButtonStyle = {
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			'&:hover': {
				backgroundColor: 'rgba(255, 255, 255, 0.9)',
			},
		};

	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="attachment-modal-title">
			<Box className="attachment-modal-container">
				<Box className="attachment-modal-header">
					<Typography id="attachment-modal-title" variant="h6" component="h2">
						Attachments ({currentIndex + 1}/{attachments.length})
					</Typography>
					<IconButton onClick={handleClose} size="large">
						<CloseIcon />
					</IconButton>
				</Box>
				<Box className="attachment-modal-content">
					{!isSmallScreen && (
						<List className="attachment-list" style={{ width: FILE_LIST_WIDTH }}>
							{attachments.map((attachment, index) => (
								<ListItem
									key={index}
									button
									selected={index === currentIndex}
									onClick={() => setCurrentIndex(index)}
									className={`attachment-list-item ${index === currentIndex ? 'selected' : ''}`}
								>
									<ListItemText primary={attachment.filename} className="attachment-list-item-text" />
									<ListItemSecondaryAction>
										<IconButton edge="end" onClick={() => handleDownload(attachment)}>
											<DownloadIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
					)}
					<Box className="attachment-display-container">
						{sasToken ? (
							renderContent(currentAttachment)
						) : (
							<Box className="attachment-loading">
								<CircularProgress />
							</Box>
						)}
						{attachments.length > 1 && (
							<>
								<IconButton onClick={prevAttachment} sx={{ ...navButtonStyle, left: 16 }}>
									<ChevronLeftIcon />
								</IconButton>
								<IconButton onClick={nextAttachment} sx={{ ...navButtonStyle, right: 16 }}>
									<ChevronRightIcon />
								</IconButton>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default AttachmentModal;
