import React, { useMemo, useState } from 'react';

import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import GridEditFormDialog from '../../../components/gridEditFormDialog';
import { SIZE } from '../../../hooks/useScreenSize';

function ActivityFormDialog(props) {
	const { table, row } = props,
		[validationErrors, setValidationErrors] = useState({}),
		[description, setDescription] = useState(''),
		[defaultIsBillable, setDefaultIsBillable] = useState(false),
		[isTimecardRelated, setIsTimecardRelated] = useState(false),
		[isObservationRelated, setIsObservationRelated] = useState(false),
		onValidate = (errors) => {
			setValidationErrors(errors);
		},
		// eslint-disable-next-line no-unused-vars
		updateStates = useMemo(() => {
			if (row._valuesCache['description'] !== undefined) setDescription(row._valuesCache['description']);
			if (row._valuesCache['defaultIsBillable'] !== undefined)
				setDefaultIsBillable(row._valuesCache['defaultIsBillable']);
			if (row._valuesCache['isTimecardRelated'] !== undefined)
				setIsTimecardRelated(row._valuesCache['isTimecardRelated']);
			if (row._valuesCache['isObservationRelated'] !== undefined)
				setIsObservationRelated(row._valuesCache['isObservationRelated']);
			if (row._validationErrors) setValidationErrors(row._validationErrors);
		}, [row._valuesCache, row._validationErrors]);
	return (
		<GridEditFormDialog
			table={table}
			row={row}
			objectType="Activity"
			requiredFieldMap={{ description: 'Description' }}
			onValidate={onValidate}
		>
			<TextField
				label="Description"
				required
				size={SIZE.small ? SIZE.small : SIZE.medium}
				value={description}
				fullWidth
				error={!!validationErrors?.description}
				helperText={validationErrors?.description}
				onFocus={() =>
					setValidationErrors({
						...validationErrors,
						description: undefined,
					})
				}
				onChange={(event) => {
					const newValue = event.currentTarget.value;
					setDescription(newValue);
					row._valuesCache['description'] = newValue;
				}}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={defaultIsBillable}
						onChange={(event, newValue) => {
							setDefaultIsBillable(newValue);
							row._valuesCache['defaultIsBillable'] = newValue;
						}}
					/>
				}
				label="Default Billable?"
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={isTimecardRelated}
						onChange={(event, newValue) => {
							setIsTimecardRelated(newValue);
							row._valuesCache['isTimecardRelated'] = newValue;
						}}
					/>
				}
				label="Timecard Related?"
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={isObservationRelated}
						onChange={(event, newValue) => {
							setIsObservationRelated(newValue);
							row._valuesCache['isObservationRelated'] = newValue;
						}}
					/>
				}
				label="Observation Related?"
			/>
		</GridEditFormDialog>
	);
}

export default ActivityFormDialog;
