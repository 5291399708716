import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack, Tabs, Tab } from '@mui/material';
import { differenceInMinutes } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE } from './constants';
import Observations from './observations';
import ObservationEntryFormDialog from './observations/observationEntryFormDialog';
import Timecards from './timecards';
import TimecardEntryFormDialog from './timecards/timecardEntryFormDialog';
import { ROLESDATA } from '../../components/aclContainer/roles';
import AppContainer from '../../components/appContainer';
import { AuthContext, LoadingContext } from '../../context';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { useScreenSize } from '../../hooks/useScreenSize';

function LogsPage() {
	const { size } = useScreenSize(),
		{ httpGet } = useFetchWithMsal(),
		{ canAccess, getUserId } = useContext(AuthContext),
		{ enqueueSnackbar } = useSnackbar(),
		{ startLoading, stopLoading } = React.useContext(LoadingContext),
		navigate = useNavigate(),
		location = useLocation(),
		[tabIndex, setTabIndex] = useState(0),
		[opened, setOpened] = useState([]),
		handleTabChange = useCallback(
			(event, newValue) => {
				const path = newValue === 0 ? '/timecards' : '/observations';
				if (newValue === 0) {
					setIsTimecardDataLoaded(false);
				} else {
					setIsObservationDataLoaded(false);
				}
				navigate(path);
			},
			[navigate]
		),
		FORCE_REFRESH_INTERVAL = 10,
		// TIMECARDS
		[isFetchingTimecardData, setIsFetchingTimecardData] = useState(false),
		[isTimecardDataLoaded, setIsTimecardDataLoaded] = useState(false),
		updateIsTimecardDataLoaded = useCallback((newValue) => {
			setIsTimecardDataLoaded(newValue);
		}, []),
		[openTimecardDialog, setOpenTimecardDialog] = useState(false),
		[timecardData, setTimecardData] = useState([]),
		[lastTimecardFetch, setLastTimecardFetch] = useState(null),
		loadTimecardData = useCallback(
			(forceRefresh = false) => {
				const shouldRefresh =
					forceRefresh ||
					!lastTimecardFetch ||
					differenceInMinutes(new Date(), lastTimecardFetch) >= FORCE_REFRESH_INTERVAL;

				if (shouldRefresh) {
					setIsFetchingTimecardData(true);
					if (forceRefresh) startLoading();
					const queryParams = {};
					if (canAccess([ROLESDATA.Installer.RoleName])) {
						queryParams.userId = getUserId();
					}
					httpGet({ urlSubPath: 'timecards', queryParams })
						.then((data) => {
							setTimecardData(data);
							setIsTimecardDataLoaded(true);
							setLastTimecardFetch(new Date());
						})
						.catch((error) =>
							enqueueSnackbar(`Unable to load timecards. ${error}`, {
								variant: 'error',
								preventDuplicate: true,
							})
						)
						.finally(() => {
							setIsFetchingTimecardData(false);
							if (forceRefresh) stopLoading();
						});
				}
			},
			[enqueueSnackbar, httpGet, startLoading, stopLoading, canAccess, getUserId, lastTimecardFetch]
		),
		handleClickLogTimecard = () => {
			setOpenTimecardDialog(true);
		},
		handleCloseTimecardDialog = () => {
			setOpenTimecardDialog(false);
			loadTimecardData(true);
		},
		// OBSERVATIONS
		[isFetchingObservationData, setIsFetchingObservationData] = useState(false),
		[isObservationDataLoaded, setIsObservationDataLoaded] = useState(false),
		updateIsObservationDataLoaded = useCallback((newValue) => {
			setIsObservationDataLoaded(newValue);
		}, []),
		[openObservationDialog, setOpenObservationDialog] = useState(false),
		[observationData, setObservationData] = useState([]),
		[lastObservationFetch, setLastObservationFetch] = useState(null),
		loadObservationData = useCallback(
			(forceRefresh = false) => {
				const shouldRefresh =
					forceRefresh ||
					!lastObservationFetch ||
					differenceInMinutes(new Date(), lastObservationFetch) >= FORCE_REFRESH_INTERVAL;

				if (shouldRefresh) {
					setIsFetchingObservationData(true);
					if (forceRefresh) startLoading();
					const queryParams = {};
					if (canAccess([ROLESDATA.Installer.RoleName])) {
						queryParams.userId = getUserId();
					}
					httpGet({ urlSubPath: 'observations', queryParams })
						.then((data) => {
							setObservationData(data);
							setIsObservationDataLoaded(true);
							setLastObservationFetch(new Date());
						})
						.catch((error) =>
							enqueueSnackbar(`Unable to load observations. ${error}`, {
								variant: 'error',
								preventDuplicate: true,
							})
						)
						.finally(() => {
							setIsFetchingObservationData(false);
							if (forceRefresh) stopLoading();
						});
				}
			},
			[enqueueSnackbar, httpGet, startLoading, stopLoading, canAccess, getUserId, lastObservationFetch]
		),
		handleClickLogObservation = () => {
			setOpenObservationDialog(true);
		},
		handleCloseObservationDialog = () => {
			setOpenObservationDialog(false);
			loadObservationData(true);
		};
	// EFFECTS
	// side-effects related to tabs
	useEffect(() => {
		const path = location.pathname;
		if (path.includes('/timecards')) {
			setTabIndex(0);
		} else if (path.includes('/observations')) {
			setTabIndex(1);
		}
	}, [location]);

	// Combined data loading effect
	useEffect(() => {
		// Load initial data or handle tab changes
		if (tabIndex === 0 && !isFetchingTimecardData && !isTimecardDataLoaded) {
			loadTimecardData(true);
		} else if (tabIndex === 1 && !isFetchingObservationData && !isObservationDataLoaded) {
			loadObservationData(true);
		}
	}, [
		tabIndex,
		isFetchingTimecardData,
		isTimecardDataLoaded,
		isFetchingObservationData,
		isObservationDataLoaded,
		loadTimecardData,
		loadObservationData,
	]);

	// Add interval ref to avoid recreating the interval on each render
	const intervalRef = useRef(null);

	// Add a new effect for setting up the interval
	useEffect(() => {
		const checkAndRefreshData = () => {
			if (tabIndex === 0) {
				loadTimecardData();
			} else if (tabIndex === 1) {
				loadObservationData();
			}
		};

		intervalRef.current = setInterval(checkAndRefreshData, FORCE_REFRESH_INTERVAL * 60 * 1000);

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [tabIndex, loadTimecardData, loadObservationData]);

	// Move URL parameter handling to the top level and make it respond to location changes
	const [urlParams, setUrlParams] = useState({
		page: 1,
		pageSize: DEFAULT_PAGE_SIZE,
		initialExpandedRows: {},
	});

	// Add effect to handle URL parameters
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search),
			page = parseInt(searchParams.get('page') || '1', 10),
			pageSize = parseInt(searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`, 10),
			openedParam = searchParams.get('opened'),
			initialExpandedRows = openedParam
				? openedParam.split('_').reduce((acc, id) => ({ ...acc, [id]: true }), {})
				: {};

		setUrlParams({ page, pageSize, initialExpandedRows });
	}, [location.search]);

	useEffect(() => {
		const openedString = location.search.split('opened=')[1]?.split('&')[0];
		if (openedString) {
			const openedFromUrl = openedString.split('_').map(Number);
			setOpened(openedFromUrl);

			// Add this to ensure URL is updated on mount
			const searchParams = new URLSearchParams(window.location.search);
			searchParams.set('opened', openedString);
			const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
			window.history.replaceState(null, '', newUrl);
		}
	}, []); // Run only on mount
	return (
		<AppContainer>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
					alignItems: { xs: 'stretch', md: 'center' },
					justifyContent: 'space-between',
				}}
			>
				<Tabs
					value={tabIndex}
					onChange={handleTabChange}
					sx={{
						minHeight: { xs: 48, md: 'auto' },
						'& .MuiTab-root': {
							minHeight: { xs: 48, md: 'auto' },
							py: { xs: 1, md: 'auto' },
							width: { xs: '50%', md: 'auto' },
							minWidth: { md: 250 },
						},
						display: { xs: 'flex', md: 'inline-flex' },
						width: { xs: '100%', md: 'auto' },
						order: { xs: 2, md: 1 },
						bgcolor: { xs: 'background.paper', md: 'transparent' },
					}}
				>
					<Tab label="Timecards" />
					<Tab label="Observations" />
				</Tabs>
				<Stack
					spacing={2}
					direction="row"
					sx={{
						width: { xs: '100%', md: 'auto' },
						order: { xs: 1, md: 2 },
						px: { xs: 1, md: 0 },
						pt: { xs: 1, md: 0 },
						mr: { md: '15px' },
					}}
				>
					<Button
						variant="contained"
						size={size}
						startIcon={<AddIcon />}
						onClick={handleClickLogTimecard}
						sx={{ flexGrow: { xs: 1, md: 0 }, minWidth: { xs: 160, md: 180 } }}
					>
						Timecard
					</Button>
					<Button
						variant="contained"
						size={size}
						startIcon={<AddIcon />}
						onClick={handleClickLogObservation}
						sx={{ flexGrow: { xs: 1, md: 0 }, minWidth: { xs: 160, md: 180 } }}
					>
						Observation
					</Button>
				</Stack>
			</Box>
			<Box>
				{tabIndex === 0 && (
					<Timecards
						timecardData={timecardData}
						timecardDataRefreshed={setIsTimecardDataLoaded}
						initialPage={urlParams.page - 1}
						initialPageSize={urlParams.pageSize}
						initialExpandedRows={urlParams.initialExpandedRows}
						opened={opened}
						setOpened={setOpened}
					/>
				)}
				{tabIndex === 1 && (
					<Observations
						observationData={observationData}
						observationDataRefreshed={setIsObservationDataLoaded}
						initialPage={urlParams.page - 1}
						initialPageSize={urlParams.pageSize}
						initialExpandedRows={urlParams.initialExpandedRows}
						opened={opened}
						setOpened={setOpened}
					/>
				)}
			</Box>
			<TimecardEntryFormDialog
				open={openTimecardDialog}
				onClose={handleCloseTimecardDialog}
				timecardDataRefreshed={updateIsTimecardDataLoaded}
				opened={opened}
				setOpened={setOpened}
			/>
			<ObservationEntryFormDialog
				open={openObservationDialog}
				onClose={handleCloseObservationDialog}
				observationDataRefreshed={updateIsObservationDataLoaded}
				opened={opened}
				setOpened={setOpened}
			/>
		</AppContainer>
	);
}

export default LogsPage;
