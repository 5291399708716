export const EntryProjectLogKeys = {
	ACTIVITY: 'activity',
	ACTIVITY_DATE: 'activityDate',
	HOURS_SPENT: 'hoursSpent',
	PROJECT: 'project',
	BUILDING_DIRECTION_1: 'buildingDirection1',
	BUILDING_DIRECTION_2: 'buildingDirection2',
	UNITS: 'units',
	UNIT_ACTIVITIES: 'unitActivities',
	QUANTITY_ENTERED: 'quantityEntered',
};

export const DEFAULT_PAGE_SIZE = 100;

export class EntryTimecardRequest {
	constructor({ id, timecardDate, jobId, activityId, userId, hours, isBillable, notes, attachments, isSubmitted }) {
		this.body = {};
		if (id) this.body['id'] = id.toString();
		this.body['timecardDate'] = timecardDate;
		this.body['jobId'] = jobId?.toString() ?? null;
		this.body['activityId'] = activityId.toString();
		this.body['userId'] = userId.toString();
		this.body['hours'] = hours;
		this.body['isBillable'] = isBillable;
		this.body['notes'] = notes;
		this.body['attachments'] = attachments ?? [];
		this.body['isSubmitted'] = isSubmitted;
	}

	getBody() {
		return this.body;
	}
}

export class EntryObservationRequest {
	constructor({ id, observationDateTime, jobId, activityId, userId, notes, attachments, isSubmitted }) {
		this.body = {};
		if (id) this.body['id'] = id.toString();
		this.body['observationDateTime'] = observationDateTime;
		this.body['jobId'] = jobId?.toString() ?? null;
		this.body['activityId'] = activityId.toString();
		this.body['userId'] = userId.toString();
		this.body['notes'] = notes;
		this.body['attachments'] = attachments ?? [];
		this.body['isSubmitted'] = isSubmitted;
	}

	getBody() {
		return this.body;
	}
}
