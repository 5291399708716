import React, { useState, useEffect } from 'react';

import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	IconButton,
} from '@mui/material';

import useFetchWithMsal from '../../../hooks/useFetchWithMsal';

export default function WorkItemManager({ projectId, locationId }) {
	const [workItems, setWorkItems] = useState([]),
		{ httpGet, httpPost, httpPut, httpDelete } = useFetchWithMsal();

	useEffect(() => {
		fetchWorkItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId, locationId]);

	const fetchWorkItems = async () => {
			try {
				const workItemsResponse = await httpGet({
					urlSubPath: `projects/${projectId}/locations/${locationId}/work-items`,
				});
				setWorkItems(workItemsResponse);
			} catch (error) {
				console.error('Error fetching work items:', error);
			}
		},
		handleAddWorkItem = async (newWorkItem) => {
			try {
				await httpPost({
					urlSubPath: `projects/${projectId}/locations/${locationId}/work-items`,
					body: newWorkItem,
				});
				await fetchWorkItems();
			} catch (error) {
				console.error('Error adding work item:', error);
			}
		},
		handleEditWorkItem = async (workItemId, updatedWorkItem) => {
			try {
				await httpPut({
					urlSubPath: `projects/${projectId}/locations/${locationId}/work-items/${workItemId}`,
					body: updatedWorkItem,
				});
				await fetchWorkItems();
			} catch (error) {
				console.error('Error updating work item:', error);
			}
		},
		handleDeleteWorkItem = async (workItemId) => {
			try {
				await httpDelete({
					urlSubPath: `projects/${projectId}/locations/${locationId}/work-items/${workItemId}`,
				});
				await fetchWorkItems();
			} catch (error) {
				console.error('Error deleting work item:', error);
			}
		};

	return (
		<Box sx={{ mt: 2 }}>
			<Typography variant="h6">Work Item Management</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Activity</TableCell>
							<TableCell>Hardware</TableCell>
							<TableCell>Quantity</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{workItems.map((workItem) => (
							<TableRow key={workItem.id}>
								<TableCell>{workItem.activityId}</TableCell>
								<TableCell>{workItem.hardwareId}</TableCell>
								<TableCell>{workItem.quantity}</TableCell>
								<TableCell>
									<IconButton
										onClick={() =>
											handleEditWorkItem(workItem.id, {
												/* updated data */
											})
										}
									>
										<EditIcon />
									</IconButton>
									<IconButton onClick={() => handleDeleteWorkItem(workItem.id)}>
										<DeleteIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				startIcon={<AddIcon />}
				onClick={() =>
					handleAddWorkItem({
						/* new work item data */
					})
				}
			>
				Add Work Item
			</Button>
		</Box>
	);
}
