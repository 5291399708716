import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, Link } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useSnackbar } from 'notistack';

import ReportRecipientDialog from './reportRecipientDialog';
import { ROLESDATA } from '../../components/aclContainer';
import AppContainer from '../../components/appContainer';
import { LoadingContext } from '../../context';
import { AuthContext } from '../../context';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { useScreenSize, SIZE } from '../../hooks/useScreenSize';
import { logger } from '../../utils/loggingUtils';
export default function ReportsPage() {
	const { canAccess } = useContext(AuthContext),
		isAdmin = canAccess([ROLESDATA.Administrator.RoleName]),
		[reports, setReports] = useState([]),
		[isReportsLoaded, setIsReportsLoaded] = useState(false),
		[isFetchingReports, setIsFetchingReports] = useState(false),
		{ startLoading, stopLoading } = React.useContext(LoadingContext),
		{ httpPut, httpGet } = useFetchWithMsal(),
		{ size } = useScreenSize(),
		{ enqueueSnackbar } = useSnackbar(),
		[isRecipientDialogOpen, setIsRecipientDialogOpen] = useState(false),
		[currentReport, setCurrentReport] = useState(null),
		handleSaveReport = async (values, reportId) => {
			startLoading();
			try {
				await httpPut({
					urlSubPath: `reports/${reportId}`,
					body: values,
				});
				enqueueSnackbar('Report updated successfully', { variant: 'success' });
				setIsReportsLoaded(false);
			} catch (error) {
				enqueueSnackbar(`Unable to update report. ${error}`, { variant: 'error' });
			} finally {
				stopLoading();
			}
		},
		openRecipientDialog = (report) => {
			setCurrentReport(report);
			setIsRecipientDialogOpen(true);
		},
		closeRecipientDialog = () => {
			setIsRecipientDialogOpen(false);
			setCurrentReport(null);
		},
		saveRecipients = (newRecipients) => {
			if (currentReport) {
				currentReport.reportSnapshots = [];
				const updatedReport = { ...currentReport, reportRecipients: newRecipients };
				handleSaveReport(updatedReport, currentReport.id);
			}
			closeRecipientDialog();
		},
		loadReports = useCallback(() => {
			if (isFetchingReports) return;
			setIsFetchingReports(true);
			startLoading();
			httpGet({
				urlSubPath: 'reports',
			})
				.then((response) => {
					setReports(response);
					setIsReportsLoaded(true);
				})
				.catch((error) => {
					logger.error(error);
					enqueueSnackbar(`Unable to load reports. ${error}`, {
						variant: 'error',
						preventDuplicate: true,
					});
				})
				.finally(() => {
					setIsFetchingReports(false);
					stopLoading();
				});
		}, [enqueueSnackbar, httpGet, startLoading, stopLoading, isFetchingReports]),
		columns = useMemo(
			() => [
				{
					accessorKey: 'id',
					header: 'ID',
					size: size === SIZE.small ? 100 : 150,
				},
				{
					accessorKey: 'name',
					header: 'Report Name',
					size: size === SIZE.small ? 150 : 200,
				},
				{
					accessorKey: 'reportRecipients',
					header: 'Recipients',
					size: size === SIZE.small ? 200 : 300,
					Cell: ({ cell, row }) => (
						<Box>
							{cell.getValue().map((recipient, index) => (
								<div key={index}>{recipient.recipientEmail}</div>
							))}
							{isAdmin && (
								<Link
									component="button"
									variant="body2"
									onClick={() => openRecipientDialog(row.original)}
								>
									Edit Recipients
								</Link>
							)}
						</Box>
					),
				},
			],
			[size, isAdmin]
		),
		table = useMaterialReactTable({
			columns,
			data: reports,
			enableEditing: false,
			enableFullScreenToggle: false,
			getRowId: (row) => row.id,
			muiTableContainerProps: {
				sx: {
					minHeight: '500px',
					maxHeight: '70vh',
				},
			},
			initialState: {
				pagination: {
					pageSize: 50,
				},
				sorting: [
					{
						id: 'id',
						desc: false,
					},
				],
			},
			renderTopToolbarCustomActions: () => (
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<IconButton onClick={loadReports}>
						<RefreshIcon />
					</IconButton>
				</Box>
			),
		});

	useEffect(() => {
		if (!isReportsLoaded && !isFetchingReports) {
			loadReports();
		}
	}, [isReportsLoaded, isFetchingReports, loadReports]);

	return (
		<AppContainer>
			<MaterialReactTable table={table} />
			{currentReport && (
				<ReportRecipientDialog
					open={isRecipientDialogOpen}
					onClose={closeRecipientDialog}
					report={currentReport}
					onSave={saveRecipients}
				/>
			)}
		</AppContainer>
	);
}
