import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { FeatureFlagProvider } from './FeatureFlagProvider';
import { turnkeyTheme } from '../theme/TurnKeyTheme';

export const AppProviders = ({ children }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<ThemeProvider theme={turnkeyTheme}>
				<CssBaseline />
				<FeatureFlagProvider>{children}</FeatureFlagProvider>
			</ThemeProvider>
		</LocalizationProvider>
	);
};
