import React, { createContext, useState, useContext, useCallback } from 'react';

import { AuthContext } from '../context/auth/AuthContext';
import { getFeatureFlags, isFeatureEnabled } from '../services/featureFlagService';

export const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ children }) => {
	const [flags, setFlags] = useState(null),
		{ getAccessToken, getUserId } = useContext(AuthContext),
		initializeFlags = useCallback(async () => {
			const userId = getUserId();
			if (!userId) return; // Don't fetch if user ID is not available
			const accessToken = await getAccessToken(),
				fetchedFlags = await getFeatureFlags(accessToken);
			setFlags(fetchedFlags);
		}, [getAccessToken, getUserId]),
		checkFeatureFlag = useCallback(
			async (flagName) => {
				const userId = getUserId();
				if (!userId) return false; // Return false if user ID is not available
				if (!flags) {
					await initializeFlags();
				}
				const accessToken = await getAccessToken();
				return isFeatureEnabled(flagName, accessToken, userId);
			},
			[flags, initializeFlags, getAccessToken, getUserId]
		);

	return (
		<FeatureFlagContext.Provider value={{ flags, initializeFlags, checkFeatureFlag }}>
			{children}
		</FeatureFlagContext.Provider>
	);
};

export const useFeatureFlags = () => useContext(FeatureFlagContext);
