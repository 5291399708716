import { BlobServiceClient } from '@azure/storage-blob';

export class BlobService {
	constructor(sasToken) {
		this.accountName = process.env.REACT_APP_ABS_STORAGE_ACCOUNT_NAME;
		this.sasToken = sasToken;
		this.blobServiceClient = null;
	}

	async initialize() {
		if (!this.blobServiceClient) {
			this.blobServiceClient = new BlobServiceClient(
				`https://${process.env.REACT_APP_ABS_STORAGE_ACCOUNT_NAME}.blob.core.windows.net?${this.sasToken}`
			);
		}
	}

	async uploadBlob(containerName, blobName, content) {
		await this.initialize();

		try {
			const containerClient = this.blobServiceClient.getContainerClient(containerName),
				blockBlobClient = containerClient.getBlockBlobClient(blobName),
				uploadResponse = await blockBlobClient.uploadData(content, {
					blobHTTPHeaders: { blobContentType: content.type },
				});
			console.debug(`Blob ${blobName} uploaded successfully`, uploadResponse.requestId);
			return {
				bucket: containerName,
				originalFilename: content.name,
				filename: blobName,
				url: blockBlobClient.url.split('?')[0],
				contentType: content.type,
			};
		} catch (error) {
			console.error('Error uploading blob:', error.message);
			throw error;
		}
	}

	async uploadBlobs(containerName, blobs) {
		try {
			const promises = blobs.map((blob) => this.uploadBlob(containerName, `${Date.now()}-${blob.name}`, blob));
			return await Promise.all(promises);
		} catch (error) {
			console.error('Error uploading blobs:', error.message);
			throw error;
		}
	}

	async getBlob(containerName, blobName) {
		await this.initialize();

		try {
			const containerClient = this.blobServiceClient.getContainerClient(containerName),
				blockBlobClient = containerClient.getBlockBlobClient(blobName),
				downloadResponse = await blockBlobClient.download();
			console.debug(`Blob ${blobName} downloaded successfully`, downloadResponse.requestId);
			return downloadResponse;
		} catch (error) {
			console.error('Error downloading blob:', error.message);
			throw error;
		}
	}
}
