import React, { useState, useEffect, useContext, useCallback } from 'react';

import { Box, Grid, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import LocationWorkItemManager from './LocationWorkItemManager';
import AppContainer from '../../../components/appContainer';
import TreeView from '../../../components/treeView';
import { LoadingContext } from '../../../context';
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';

const transformLocationsToTreeData = (project) => {
	// Check if project or project.locations is null or undefined
	if (!project) {
		return null;
	}

	// Helper function to recursively build the tree
	const buildTree = (parentId = null) => {
		return project.locations
			.filter((location) => location.parentId === parentId)
			.map((location) => ({
				id: location.id,
				name: location.name,
				children: buildTree(location.id),
			}))
			.filter((node) => node.children.length > 0 || !node.children.some((child) => child.id === node.id));
	};

	// Create the root node with project details
	return {
		id: project.id,
		name: project.name,
		children: buildTree(),
	};
};

export default function ProjectDetailsPage() {
	const { projectId } = useParams(),
		[project, setProject] = useState(null),
		[selectedLocationId, setSelectedLocationId] = useState(null),
		[locationTreeData, setLocationTreeData] = useState(null),
		{ httpGet } = useFetchWithMsal(),
		[isFetchingProjectDetails, setIsFetchingProjectDetails] = useState(false),
		[isProjectDetailsLoaded, setIsProjectDetailsLoaded] = useState(false),
		{ startLoading, stopLoading } = useContext(LoadingContext),
		{ enqueueSnackbar } = useSnackbar(),
		fetchProjectDetails = useCallback(async () => {
			if (isFetchingProjectDetails) return;
			setIsFetchingProjectDetails(true);
			startLoading();
			console.log('fetchProjectDetails', projectId);
			try {
				const projectResponse = await httpGet({ urlSubPath: `projects/${projectId}` });
				console.log('projectResponse', projectResponse);
				setProject(projectResponse);
				// Transform the entire project object into a tree structure
				const treeData = transformLocationsToTreeData(projectResponse);
				setLocationTreeData(treeData);
				setIsProjectDetailsLoaded(true);
			} catch (error) {
				console.error('Error fetching project details:', error);
				enqueueSnackbar('Unable to load project details', { variant: 'error', preventDuplicate: true });
			} finally {
				stopLoading();
				setIsFetchingProjectDetails(false);
			}
		}, [startLoading, projectId, httpGet, enqueueSnackbar, stopLoading, isFetchingProjectDetails]);

	useEffect(() => {
		if (!isProjectDetailsLoaded && !isFetchingProjectDetails) {
			fetchProjectDetails();
		}
	}, [isProjectDetailsLoaded, isFetchingProjectDetails, fetchProjectDetails]);

	if (!project || !locationTreeData) {
		return null; // The LoadingOverlay will be shown by the AppContainer
	}

	return (
		<AppContainer>
			<Box sx={{ flexGrow: 1, p: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={8}>
						<Paper sx={{ p: 2 }}>
							<LocationWorkItemManager
								project={project}
								selectedLocationId={selectedLocationId}
								setSelectedLocationId={setSelectedLocationId}
							/>
						</Paper>
					</Grid>
					<Grid item xs={12} md={4}>
						<Paper sx={{ p: 2 }}>
							<TreeView
								dataObject={locationTreeData}
								onItemSelect={setSelectedLocationId}
								title="Locations"
							/>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</AppContainer>
	);
}
