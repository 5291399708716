import React, { useContext } from 'react';

import { useMsal } from '@azure/msal-react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../../context';

const BoxWrapper = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		width: '90vw',
	},
}));

export const Error401 = () => {
	const { instance } = useMsal(),
		{ isActive, canAccess } = useContext(AuthContext),
		userHasSomeAccess = isActive && canAccess(),
		logout = () => {
			instance.logout();
		};

	return (
		<Box className="content-center">
			<Box sx={{ p: 5, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
				<BoxWrapper>
					<Typography variant="h1" sx={{ mb: 2.5 }}>
						401
					</Typography>
					<Typography variant="h5" sx={{ mb: 2.5, fontSize: '1.5rem !important' }}>
						You are not authorized! 🔐
					</Typography>
					<Typography variant="body2">
						You don&prime;t have permission to access this page. Possibly your access request is pending.
						Please contact Turnkey Interiors support.{' '}
					</Typography>
				</BoxWrapper>
				<Button
					href="/"
					variant="contained"
					sx={{ px: 5.5, mt: 2.5, visibility: !userHasSomeAccess ? 'hidden' : 'inherit' }}
					to="/"
				>
					Go Home
				</Button>

				<Button component="a" variant="contained" sx={{ px: 5.5, mt: 2.5 }} onClick={() => logout()}>
					Logout
				</Button>
			</Box>
		</Box>
	);
};

export default Error401;
