import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

export const turnkeyTheme = createTheme({
	palette: {
		primary: {
			main: '#057abd',
		},
		secondary: {
			main: '#2C8CC3',
		},
		error: {
			main: red.A400,
		},
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.87)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: 'rgba(0, 0, 0, 0.38)',
		},
	},
});
