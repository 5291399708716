import { getAppInsights } from '../services/appInsightsService';

export const logger = {
	error: (message, properties) => {
		console.error(message);
		getAppInsights()?.trackException({ exception: new Error(message), properties });
	},
	warn: (message, properties) => {
		console.warn(message);
		getAppInsights()?.trackTrace({ message, severityLevel: 2, properties });
	},
	info: (message, properties) => {
		console.info(message);
		getAppInsights()?.trackTrace({ message, severityLevel: 1, properties });
	},
	debug: (message, properties) => {
		console.debug(message);
		getAppInsights()?.trackTrace({ message, severityLevel: 0, properties });
	},
	verbose: (message, properties) => {
		console.verbose(message);
		getAppInsights()?.trackTrace({ message, severityLevel: 0, properties });
	},
};
