import { useCallback } from 'react';

import useFetchWithMsal from './useFetchWithMsal';
import { BlobService } from '../utils/fileStorageUtils';

let blobService = null;

export function useBlobService() {
	const { httpGet } = useFetchWithMsal(),
		initializeBlobService = useCallback(async () => {
			if (!blobService) {
				try {
					const { sasToken } = await httpGet({
						urlSubPath: 'sas-token',
						queryParams: { containerName: process.env.REACT_APP_ABS_ATTACHMENTS_CONTAINER_NAME },
					});
					blobService = new BlobService(sasToken);
				} catch (error) {
					console.error('Failed to initialize BlobService:', error);
					throw error;
				}
			}
			return blobService;
		}, [httpGet]),
		uploadFiles = useCallback(
			async (files) => {
				const service = await initializeBlobService();
				return service.uploadBlobs(process.env.REACT_APP_ABS_ATTACHMENTS_CONTAINER_NAME, files);
			},
			[initializeBlobService]
		);

	return { uploadFiles };
}
