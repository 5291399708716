import React, { createContext, useState, useCallback } from 'react';

export const LoadingContext = createContext();

export function LoadingProvider({ children }) {
	const [loading, setLoading] = useState(false),
		startLoading = useCallback(() => {
			setLoading(true);
		}, []),
		stopLoading = useCallback(() => {
			setLoading(false);
		}, []);

	return <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>{children}</LoadingContext.Provider>;
}
