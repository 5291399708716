import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography, Paper, Link } from '@mui/material';

const LogDetails = ({ title, column1Data, column2Data, notes, onEdit, canEdit }) => {
	return (
		<Box
			sx={{
				p: 2,
				backgroundColor: '#f5f5f5',
				borderRadius: 1,
				width: '100%',
				margin: '0',
				cursor: 'default',
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						<strong>{title}</strong>{' '}
						{canEdit && (
							<Link
								component="button"
								onClick={onEdit}
								sx={{ textDecoration: 'none', fontSize: 'inherit' }}
							>
								Edit
							</Link>
						)}
					</Typography>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
						{column1Data.map(({ key, value, type }, index) => (
							<Typography key={index}>
								<strong>{key}:</strong>{' '}
								{type === 'icon' ? (
									value ? (
										<CheckIcon sx={{ color: 'success.main', verticalAlign: 'middle' }} />
									) : (
										<CloseIcon sx={{ color: 'error.main', verticalAlign: 'middle' }} />
									)
								) : type === 'link' ? (
									<Link component="button" onClick={value.onClick} sx={{ textDecoration: 'none' }}>
										{value.text}
									</Link>
								) : (
									value
								)}
							</Typography>
						))}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
						{column2Data.map(({ key, value, type }, index) => (
							<Typography key={index}>
								<strong>{key}:</strong>{' '}
								{type === 'icon' ? (
									value ? (
										<CheckIcon sx={{ color: 'success.main', verticalAlign: 'middle' }} />
									) : (
										<CloseIcon sx={{ color: 'error.main', verticalAlign: 'middle' }} />
									)
								) : type === 'link' ? (
									<Link component="button" onClick={value.onClick} sx={{ textDecoration: 'none' }}>
										{value.text}
									</Link>
								) : (
									value
								)}
							</Typography>
						))}
					</Box>
				</Grid>

				<Grid item xs={12} md={4}>
					<Box sx={{ mt: { xs: 2, md: 0 } }}>
						<Typography gutterBottom>
							<strong>Notes:</strong>
						</Typography>
						<Paper
							elevation={1}
							sx={{
								p: 2,
								backgroundColor: 'white',
								maxWidth: '100%',
								overflowX: 'auto',
							}}
						>
							<Typography sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
								{notes || <em>None provided for this log.</em>}
							</Typography>
						</Paper>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default LogDetails;
