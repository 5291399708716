import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import { ROLESDATA } from '../aclContainer';

const SidebarData = [
	{
		id: 0,
		title: 'Logs',
		path: '/',
		icon: <AccessTimeIcon />,
		cName: 'sidebar-text',
	},
	{
		id: 1,
		title: 'Reports',
		path: '/reports',
		icon: <LeaderboardOutlinedIcon />,
		cName: 'sidebar-text',
		allowedRoles: [ROLESDATA.Administrator.RoleName, ROLESDATA.ProjectManager.RoleName],
		featureFlag: 'REPORTS_MGMT_PAGE',
	},
	{
		id: 2,
		title: 'Activities',
		path: '/activities',
		icon: <TaskAltOutlinedIcon />,
		cName: 'sidebar-text',
		allowedRoles: [ROLESDATA.Administrator.RoleName, ROLESDATA.ProjectManager.RoleName],
	},
	{
		id: 3,
		title: 'Projects',
		path: '/projects',
		icon: <AssignmentOutlinedIcon />,
		cName: 'sidebar-text',
		allowedRoles: [ROLESDATA.Administrator.RoleName, ROLESDATA.ProjectManager.RoleName],
		children: [
			{
				id: 31,
				title: 'Hardwares',
				path: '/projects/hardwares',
				icon: <HandymanOutlinedIcon />,
				cName: 'sidebar-text nested-item',
				allowedRoles: [ROLESDATA.Administrator.RoleName, ROLESDATA.ProjectManager.RoleName],
				featureFlag: 'PROJECT_HARDWARES_PAGE',
			},
		],
	},
	{
		id: 5,
		title: 'Users',
		path: '/users',
		icon: <PersonOutlinedIcon />,
		cName: 'sidebar-text',
		allowedRoles: [ROLESDATA.Administrator.RoleName, ROLESDATA.ProjectManager.RoleName],
	},
	{
		id: 6,
		title: 'Documents',
		path: '/documents',
		icon: <FolderOpenOutlinedIcon />,
		cName: 'sidebar-text',
		featureFlag: 'PROJECT_DOCUMENTS_PORTAL',
	},
];

export default SidebarData;
