import React, { useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import AclContainer, { ROLESDATA } from './components/aclContainer';
import { BlankLayout } from './layouts/BlankLayout';
import { MainLayout } from './layouts/MainLayout';
import Error401 from './pages/401';
import Error404 from './pages/404';
import ActivityPage from './pages/activities';
import DocumentsPage from './pages/documents';
import LoadingScreen from './pages/loading';
import LogsPage from './pages/logs';
import Observations from './pages/logs/observations';
import Timecards from './pages/logs/timecards';
import ProjectManagementPage from './pages/projects';
import ProjectHardwaresPage from './pages/projects/hardwares';
import ProjectDetails from './pages/projects/projectDetails';
import ReportsPage from './pages/reports';
import UserAdmin from './pages/users';
import { useFeatureFlags } from './providers/FeatureFlagProvider';
import './styles/App.css';

function AppRoutes() {
	const { checkFeatureFlag } = useFeatureFlags(),
		[isProjectLocationsPortalEnabled, setIsProjectLocationsPortalEnabled] = useState(false),
		[isProjectHardwaresEnabled, setIsProjectHardwaresEnabled] = useState(false),
		[isProjectDocumentsEnabled, setIsProjectDocumentsEnabled] = useState(false),
		[isReportsManagementPageEnabled, setIsReportsManagementPageEnabled] = useState(false),
		[allFeatureFlagsLoaded, setAllFeatureFlagsLoaded] = useState(false);

	useEffect(() => {
		const loadFeatureFlags = async () => {
			try {
				const [locationsEnabled, hardwaresEnabled, documentsEnabled, reportsEnabled] = await Promise.all([
					checkFeatureFlag('PROJECT_LOCATIONS_PORTAL'),
					checkFeatureFlag('PROJECT_HARDWARES_PAGE'),
					checkFeatureFlag('PROJECT_DOCUMENTS_PORTAL'),
					checkFeatureFlag('REPORTS_MGMT_PAGE'),
				]);

				setIsProjectLocationsPortalEnabled(locationsEnabled);
				setIsProjectHardwaresEnabled(hardwaresEnabled);
				setIsProjectDocumentsEnabled(documentsEnabled);
				setIsReportsManagementPageEnabled(reportsEnabled);
			} finally {
				setAllFeatureFlagsLoaded(true);
			}
		};

		loadFeatureFlags();
	}, [checkFeatureFlag]);

	return (
		<BrowserRouter>
			<Routes>
				<Route element={<MainLayout />}>
					<Route element={<AclContainer isAnyRole={true} />}>
						<Route path="/" element={<Navigate replace to="/timecards" />} />
						<Route path="/timecards" element={<LogsPage />}>
							<Route index element={<Timecards />} />
							<Route path=":id/attachments" element={<Timecards />} />
						</Route>
						<Route path="/observations" element={<LogsPage />}>
							<Route index element={<Observations />} />
							<Route path=":id/attachments" element={<Observations />} />
						</Route>
					</Route>
					<Route
						element={
							<AclContainer
								allowedRoles={[ROLESDATA.Administrator.RoleName, ROLESDATA.ProjectManager.RoleName]}
							/>
						}
					>
						<Route path="/activities" element={<ActivityPage />} />
						<Route path="/projects" element={<ProjectManagementPage />} />
						{isProjectLocationsPortalEnabled && (
							<>
								<Route path="/projects/:projectId(\d+)" element={<ProjectDetails />} />
								<Route
									path="/projects/:projectId(\d+)/locations/:locationId"
									element={<ProjectDetails />}
								/>
							</>
						)}
						{isProjectHardwaresEnabled && (
							<Route path="/projects/hardwares" element={<ProjectHardwaresPage />} />
						)}
						<Route path="/users" element={<UserAdmin />} />
						{isReportsManagementPageEnabled && <Route path="/reports" element={<ReportsPage />} />}
					</Route>
					{isProjectDocumentsEnabled && <Route path="/documents" element={<DocumentsPage />} />}
				</Route>
				<Route element={<BlankLayout />}>
					<Route path="/401" element={<Error401 />} />
					<Route path="*" element={allFeatureFlagsLoaded ? <Error404 /> : <LoadingScreen />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default AppRoutes;
