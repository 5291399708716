import React, { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Stack,
	Autocomplete,
	Typography,
	Grid,
} from '@mui/material';

import useFetchWithMsal from '../../../hooks/useFetchWithMsal';

function ReportRecipientDialog({ open, onClose, report, onSave }) {
	const [localRecipients, setLocalRecipients] = useState(report?.reportRecipients || []),
		[selectedUserEmails, setSelectedUserEmails] = useState([]),
		[customExternalEmails, setCustomExternalEmails] = useState(''),
		[userEmails, setUserEmails] = useState([]),
		[showConfirmDialog, setShowConfirmDialog] = useState(false),
		[recipientsToRemove, setRecipientsToRemove] = useState([]),
		{ httpGet } = useFetchWithMsal();

	useEffect(() => {
		if (open) {
			fetchUsers();
			setLocalRecipients(report?.reportRecipients || []);
		}
	}, [open, report]);

	const fetchUsers = async () => {
			try {
				const users = await httpGet({ urlSubPath: 'users' }),
					emails = users
						.map((user) => user.email)
						.filter((email) => email && !localRecipients.some((r) => r.recipientEmail === email));
				setUserEmails(emails);
			} catch (error) {
				console.error('Error fetching users:', error);
			}
		},
		handleAddUserRecipients = () => {
			const newRecipients = selectedUserEmails
				.filter((email) => !localRecipients.some((r) => r.recipientEmail === email))
				.map((email) => ({ recipientEmail: email, isExternal: false }));
			setLocalRecipients([...localRecipients, ...newRecipients]);
			setSelectedUserEmails([]);
		},
		handleAddExternalRecipients = () => {
			const emails = customExternalEmails.split(',').map((email) => email.trim()),
				newRecipients = emails
					.filter((email) => email && !localRecipients.some((r) => r.recipientEmail === email))
					.map((email) => ({ recipientEmail: email, isExternal: true }));
			setLocalRecipients([...localRecipients, ...newRecipients]);
			setCustomExternalEmails('');
		},
		handleRemoveRecipient = (index) => {
			const newRecipients = localRecipients.filter((_, i) => i !== index);
			setLocalRecipients(newRecipients);
		},
		handleSubmit = () => {
			const removedRecipients =
				report?.reportRecipients?.filter(
					(originalRecipient) =>
						!localRecipients.some(
							(currentRecipient) => currentRecipient.recipientEmail === originalRecipient.recipientEmail
						)
				) || [];

			if (removedRecipients.length > 0) {
				setRecipientsToRemove(removedRecipients);
				setShowConfirmDialog(true);
			} else {
				onSave(localRecipients);
			}
		},
		handleConfirmRemove = () => {
			setShowConfirmDialog(false);
			onSave(localRecipients);
		},
		handleCancelRemove = () => {
			setShowConfirmDialog(false);
		};

	return (
		<>
			<Dialog open={open} onClose={onClose} fullScreen>
				<DialogTitle>Edit Recipients for {report?.name}</DialogTitle>
				<DialogContent>
					<Stack spacing={3}>
						<div>
							<Typography variant="subtitle1" gutterBottom>
								Add Internal Users
							</Typography>
							<Grid container spacing={2} alignItems="stretch">
								<Grid item xs={10}>
									<Autocomplete
										multiple
										options={userEmails}
										renderInput={(params) => (
											<TextField {...params} label="Select User Emails" fullWidth />
										)}
										value={selectedUserEmails}
										onChange={(event, newValue) => {
											setSelectedUserEmails(newValue);
										}}
										fullWidth
									/>
								</Grid>
								<Grid item xs={2}>
									<Button
										onClick={handleAddUserRecipients}
										variant="contained"
										disabled={selectedUserEmails.length === 0}
										fullWidth
										sx={{ height: '100%' }}
									>
										Add Users
									</Button>
								</Grid>
							</Grid>
						</div>

						<div>
							<Typography variant="subtitle1" gutterBottom>
								Add External Emails
							</Typography>
							<Grid container spacing={2} alignItems="stretch">
								<Grid item xs={10}>
									<TextField
										label="External Emails (comma-separated)"
										value={customExternalEmails}
										onChange={(e) => setCustomExternalEmails(e.target.value)}
										fullWidth
									/>
								</Grid>
								<Grid item xs={2}>
									<Button
										onClick={handleAddExternalRecipients}
										variant="contained"
										disabled={!customExternalEmails.trim()}
										fullWidth
										sx={{ height: '100%' }}
									>
										Add External
									</Button>
								</Grid>
							</Grid>
						</div>

						<Typography variant="h6">Recipients</Typography>
						<List>
							{localRecipients.map((recipient, index) => (
								<ListItem key={index}>
									<ListItemText
										primary={recipient.recipientEmail}
										secondary={recipient.isExternal ? 'External address' : 'User'}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="delete"
											onClick={() => handleRemoveRecipient(index)}
										>
											<DeleteIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button onClick={handleSubmit} variant="contained">
						Save
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={showConfirmDialog} onClose={handleCancelRemove}>
				<DialogTitle>Confirm Removal</DialogTitle>
				<DialogContent>
					<Typography gutterBottom>
						Are you sure you want to remove the following users from this report?
					</Typography>
					<List>
						{recipientsToRemove.map((recipient, index) => (
							<ListItem key={index}>
								<ListItemText
									primary={recipient.recipientEmail}
									secondary={recipient.isExternal ? 'External address' : 'User'}
								/>
							</ListItem>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelRemove}>Cancel</Button>
					<Button onClick={handleConfirmRemove} color="error" variant="contained">
						Remove
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ReportRecipientDialog;
