import React from 'react';

import Box from '@mui/material/Box';

export const AppContainer = (props) => {
	const { children } = props;

	return (
		<Box
			pt={2}
			flex={1}
			display="flex"
			flexDirection="column"
			sx={{
				paddingLeft: { xs: '5px', sm: '5px', md: '20px', lg: '30px' },
				paddingRight: { xs: '5px', sm: '5px', md: '20px', lg: '30px' },
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{children}
			</Box>
		</Box>
	);
};
export default AppContainer;
