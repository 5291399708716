import React from 'react';

import { Button, Typography, FormControl } from '@mui/material';

function FileUploadInput({
	selectedFiles,
	onFileChange,
	buttonText = 'Attach files',
	allowMultiple = true,
	acceptedFileTypes,
	maxFileSize,
	maxFiles,
	customButton,
	showFileList = true,
	fileListStyle,
}) {
	const handleFileChange = (event) => {
		const newFiles = Array.from(event.target.files),
			// Function to check if two files are the same
			areFilesSame = (file1, file2) => {
				return (
					file1.name === file2.name && file1.size === file2.size && file1.lastModified === file2.lastModified
				);
			},
			// Combine new files with existing files, removing duplicates
			combinedFiles = [...selectedFiles];
		newFiles.forEach((newFile) => {
			const existingFileIndex = combinedFiles.findIndex((existingFile) => areFilesSame(existingFile, newFile));

			if (existingFileIndex !== -1) {
				// Replace the existing file
				combinedFiles[existingFileIndex] = newFile;
			} else {
				// Add the new file
				combinedFiles.push(newFile);
			}
		});

		// Apply file filters
		const filteredFiles = combinedFiles.filter((file) => {
				if (maxFileSize && file.size > maxFileSize) return false;
				return true;
			}),
			// Limit number of files
			limitedFiles = maxFiles ? filteredFiles.slice(0, maxFiles) : filteredFiles;

		onFileChange(limitedFiles);
	};

	return (
		<FormControl fullWidth>
			<input
				type="file"
				multiple={allowMultiple}
				onChange={handleFileChange}
				style={{ display: 'none' }}
				id="file-upload-input"
				accept={acceptedFileTypes ? acceptedFileTypes.join(',') : undefined}
			/>
			<label htmlFor="file-upload-input">
				{customButton || (
					<Button variant="contained" component="span">
						{buttonText}
					</Button>
				)}
			</label>
			{showFileList && selectedFiles.length > 0 && (
				<Typography variant="body2" style={{ marginTop: '8px', ...fileListStyle }}>
					{selectedFiles.length} file(s) selected: {selectedFiles.map((file) => file.name).join(', ')}
				</Typography>
			)}
		</FormControl>
	);
}

export default FileUploadInput;
