/* eslint-disable max-len */
import React, { useState, useMemo, useContext, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { ROLESDATA } from '../../../components/aclContainer/roles';
import { AuthContext } from '../../../context';
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';
import { useFeatureFlags } from '../../../providers/FeatureFlagProvider';

const SetupProjectTabPanel = ({ projects, refreshProjects, onProjectClick }) => {
		const [validationErrors, setValidationErrors] = useState({}),
			{ canAccess } = useContext(AuthContext),
			{ checkFeatureFlag } = useFeatureFlags(),
			[isProjectLocationsPortalEnabled, setIsProjectLocationsPortalEnabled] = useState(false),
			isAdmin = canAccess([ROLESDATA.Administrator.RoleName]),
			{ httpPut } = useFetchWithMsal(),
			handleCreateProject = async ({ values, table }) => {
				const newValidationErrors = validateProject(values);
				if (Object.values(newValidationErrors).some((error) => error)) {
					setValidationErrors(newValidationErrors);
					return;
				}
				setValidationErrors({});
				await createProject(values);
				table.setCreatingRow(null);
				refreshProjects();
			},
			handleSaveProject = async ({ values, table }) => {
				const newValidationErrors = validateProject(values);
				if (Object.values(newValidationErrors).some((error) => error)) {
					setValidationErrors(newValidationErrors);
					return;
				}
				setValidationErrors({});
				await updateProject(values);
				table.setEditingRow(null);
				refreshProjects();
			},
			createProject = async (project) => {
				await httpPut({
					urlSubPath: 'job',
					body: project,
				});
			},
			updateProject = async (project) => {
				await httpPut({
					urlSubPath: 'job',
					body: project,
				});
			},
			columns = useMemo(
				() => [
					{
						accessorKey: 'id',
						header: 'ID',
						enableEditing: false,
						size: 80,
					},
					{
						accessorKey: 'name',
						header: 'Project Name',
						muiEditTextFieldProps: {
							required: true,
							error: !!validationErrors?.name,
							helperText: validationErrors?.name,
							onFocus: () =>
								setValidationErrors({
									...validationErrors,
									name: undefined,
								}),
						},
					},
				],
				[validationErrors]
			),
			table = useMaterialReactTable({
				columns,
				data: projects,
				createDisplayMode: 'row',
				editDisplayMode: 'row',
				enableEditing: isAdmin,
				enableStickyHeader: true,
				enableStickyFooter: true,
				getRowId: (row) => row.id,
				muiTableContainerProps: {
					sx: {
						minHeight: '500px',
						maxHeight: 'calc(100vh - 225px)',
					},
				},
				onCreatingRowCancel: () => setValidationErrors({}),
				onCreatingRowSave: handleCreateProject,
				onEditingRowCancel: () => setValidationErrors({}),
				onEditingRowSave: handleSaveProject,
				enableRowActions: true,
				renderRowActions: ({ row }) => (
					<Box sx={{ display: 'flex', gap: '1rem' }}>
						{isAdmin && (
							<>
								<Tooltip title="Edit">
									<IconButton onClick={() => table.setEditingRow(row)}>
										<EditIcon />
									</IconButton>
								</Tooltip>
								{isProjectLocationsPortalEnabled && (
									<Tooltip title="View Details">
										<IconButton onClick={() => onProjectClick(row.original.id)}>
											<VisibilityIcon />
										</IconButton>
									</Tooltip>
								)}
							</>
						)}
					</Box>
				),
				renderTopToolbarCustomActions: () => (
					<Box sx={{ display: 'flex', gap: '1rem' }}>
						{isAdmin && (
							<Button
								variant="contained"
								onClick={() => {
									table.setCreatingRow(true);
								}}
							>
								Create New Project
							</Button>
						)}
						<IconButton onClick={refreshProjects}>
							<RefreshIcon />
						</IconButton>
					</Box>
				),
			});

		useEffect(() => {
			const checkFlag = async () => {
				const flagEnabled = await checkFeatureFlag('PROJECT_LOCATIONS_PORTAL');
				setIsProjectLocationsPortalEnabled(flagEnabled);
			};
			checkFlag();
		}, [checkFeatureFlag]);

		return <MaterialReactTable table={table} />;
	},
	validateRequired = (value) => !!value.length;

function validateProject(project) {
	return {
		name: !validateRequired(project.name) ? 'Project Name is Required' : '',
	};
}

export default SetupProjectTabPanel;
